import React, { useEffect, useState } from 'react'
import { TitleComponent } from '../Common/TitleComponent'
import OpenRequests from './Requests/OpenBooking';
import CloseRequests from './Requests/CloseBooking';
import { get } from '../../../Model/Network/Config/Axios';

function VisaRequests(props) {

    const [open, setOpen]=useState(true);
    const [openRequests, setOpenRequests] = useState([]);
    const [closeRequests, setCloseRequests] = useState([]);

    useEffect(()=>{
        get(`visa/all`,(e,r)=>{
            if(r && r.visaRequests){
                const allRequests=r.visaRequests.reverse();
                const openRequests=allRequests.filter((request)=>{
                    return request.status==="APPROVED";
                });
                const closeRequests=allRequests.filter((request)=>{
                    return request.status==="APPLIED";
                });
                setOpenRequests(openRequests);
                setCloseRequests(closeRequests);
            }
        })
    },[])

    return (
        <>
            <div className="side-container">
                <TitleComponent subtitle={"Visa Booking"} title={`Booking Request`} />
                <div
                    style={{
                        borderBottom: '2px solid rgb(241,241,241)',
                        display: 'flex',
                        marginTop: 25,
                        marginBottom: 25
                    }}>
                    <p onClick={() => {
                        setOpen(true);
                    }} style={{
                        padding: '0px 0 12px',
                        marginRight: 32,
                        fontSize: 15,
                        color: 'inherit',
                        fontWeight: 600,
                        textTransform: 'uppercase',
                        cursor: 'pointer',
                        position: 'relative',
                        top: 3
                    }} className={open ? "active-line" : null}>
                        Open Requests
                    </p>
                    <p onClick={() => {
                        setOpen(false);
                    }} style={{
                        padding: '0px 0 12px',
                        cursor: 'pointer',
                        marginRight: 32,
                        fontWeight: 600,
                        fontSize: 15,
                        color: 'inherit',
                        textTransform: 'uppercase',
                        position: 'relative',
                        top: 3
                    }} className={!open ? "active-line" : null}>
                        Closed Requests
                    </p>
                </div>
                <div style={{
                    marginTop: -15
                }}>
                    {
                        open ? <OpenRequests  data={openRequests} /> : <CloseRequests data={closeRequests} />
                    }
                </div>
                </div>
        </>
    )
}

export default VisaRequests