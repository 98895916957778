import React from 'react'
import { NormalTable } from "../../../../Tables/NormalTable/NormalTable";
import { Modal } from "../../../../libs/Modal/Modal";
import { FloatInput } from "../../../../libs/FloatLabelInput";
import { FlightRequestItem } from "../../../Common/BookingRequestItems/FlightRequestItem";
import BusRequestItem from "../../../Common/BookingRequestItems/BusRequestItem";
import { HotelRequestItem } from "../../../Common/BookingRequestItems/HotelRequestItem";
import Filter from "../../../Common/Filter/index"
import _ from 'lodash';
import { DatePicker1 } from '../../../../libs/DatePicker/DateRangePicker1';
import moment from 'moment';
import ButtonOutline from '../../../../libs/ButtonOutline';

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this)
	}


	getService = () => {
		return this.props.service;
	}

	componentDidMount() {
		this.presentor.init()
	}

	componentWillUnmount() {
		this.presentor.destroy()
	}


	state = {
		requests: [],
		decline: undefined,
		filters: [],
		type: {
			label: "By Name ",
			value: "NAME",
		},
		dateFilter: false,
		range: false,
		sort: false
	}

	_onDecline = item => {
		this.setState({
			...this.state,
			decline: {
				service: item.service,
				id: item.id
			}
		})
	}

	_onClose = item => {
		this.setState({
			...this.state,
			close: {
				service: item.service,
				id: item.id
			}
		})
	}

	

	load = r => {
		this.setState({
			...r
		})
	}

	item = item => {
		if (item.service.toLowerCase() === "flight")
			return (<FlightRequestItem init={()=>{this.presentor.init()}} onClose={this._onClose} onDecline={this._onDecline} key={item.id} history={this.props.history} data={item} />)
		else if (item.service.toLowerCase() === "bus")
			return (<BusRequestItem init={()=>{this.presentor.init()}} onClose={this._onClose}  onDecline={this._onDecline} key={item.id} history={this.props.history} data={item} />)
		else if (item.service.toLowerCase() === "hotel")
			return (<HotelRequestItem init={()=>{this.presentor.init()}} onClose={this._onClose}   onDecline={this._onDecline} key={item.id} history={this.props.history} data={item} />)
	}

	_decline = () => {
		let remarks = this._remarks.value;
		if (remarks) {
			let id = this.state.decline.id;
			this._remarks.value = ""
			if (id) {
				this.presentor.delete(this.state.decline.service, id, remarks)
				this.setState({
					...this.state,
					decline: undefined
				})
			}
		}
	}

	_close = () => {
		let remarks = this._remark.value;
		if (remarks) {
			let id = this.state.close.id;
			this._remarks.value = ""
			if (id) {
				this.presentor.close(this.state.close.service, id, remarks)
				this.setState({
					...this.state,
					close: undefined
				})
			}
		}
	}

	addFilter = (value) => {
		if (value) {
			let types = this.state.type.value;
			let filter = this.state.filters.filter(item => {
				if (item.type === types && item.value === value)
					return false;
				else return true;
			})
			filter.push({
				type:types, value
			})
			this.setState({
				...this.state,
				filters:filter
			})
		}
	}

	deleteSelectValue = item => {
		let filter =this.state.filters;
		filter = filter.filter(mItem => {
			if (item.type === mItem.type && item.value === mItem.value)
				return false;
			else return true;
		})
		this.setState({
			...this.state,
			filters:filter
		})
	}

	filter = data => {
        let filters = this.state.filters;
        let filterData = {}
        filters.forEach(f => {
            let items = filterData[f.type];
            items = items ? items : [];
            items.push(f.value)
            filterData[f.type] = items;
        })
		if(this.state.range){
			data = data.filter(el => {
				if(el.departureDate){
					let date = moment(el.departureDate,"DD-MM-YYYY").valueOf();
					if(date >= moment(this.state.range.startDate).valueOf()  && date<= moment(this.state.range.endDate).valueOf()) return true;
					else return false;
				}else if(el.checkIn){
					let date = moment(el.checkIn,"DD-MM-YYYY").valueOf();
					if(date >= moment(this.state.range.startDate) && date<= moment(this.state.range.endDate)) return true;
					else return false;
				}else return false;
			})
		}

        data = data.filter(item => {
            let nameFilter = filterData["NAME"];
            if (nameFilter) {
                if (!_.includes(nameFilter, item.employee.name))
                    return false;
            }
            let empCodeFilter = filterData["EMPCODE"];
            if(empCodeFilter) {
                if (!_.includes(empCodeFilter, item.employee.code))
                return false;
            }
            let destinationFilter = filterData["DESTINATION"];
            if(destinationFilter) {
                if (!_.includes(destinationFilter, item.destinationName))
                return false;
            }
			let tripIdFilter = filterData["TRIPID"];
            if(tripIdFilter) {
                if (!_.includes(tripIdFilter, item.tripId))
                return false;
            }
            return true;
        })
		if(this.state.sort){
			if(this.props.service === "hotels"){
				if(this.state.sort === "asc"){
					data.sort((a, b) => moment(a.checkIn,"DD-MM-YYYY").valueOf() - moment(b.checkIn,"DD-MM-YYYY").valueOf())
				}else{
					data.sort((a, b) => moment(b.checkIn,"DD-MM-YYYY").valueOf() - moment(a.checkIn,"DD-MM-YYYY").valueOf())
				}
			}else if(this.props.service === "flight" || this.props.service === "bus"){
				if(this.state.sort === "asc"){
					data.sort((a, b) => moment(a.departureDate,"DD-MM-YYYY").valueOf() - moment(b.departureDate,"DD-MM-YYYY").valueOf())
				}else{
					data.sort((a, b) => moment(b.departureDate,"DD-MM-YYYY").valueOf() - moment(a.departureDate,"DD-MM-YYYY").valueOf())
				}
			}
		}
        return data;
    }

	render() {
		return (
			<div >
				<div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
					<Filter data={this.state.requests} filters={this.state.filters} type={this.state.type} addFilter={this.addFilter} removeFilter={this.deleteSelectValue}
						onTypeChange={type => {
							this.setState((prev) => {
								return {
									...prev,
									type
								}
							})
						}}

						types={[
							{
								label: "By Name ",
								value: "NAME",
							},
							{
								label: 'By Employee Code',
								value: 'EMPCODE'
							},
							{
								label: 'By Destination',
								value: 'DESTINATION'
							},
							{
								label: 'By Trip ID',
								value: 'TRIPID' 
							},
						]}
					/>
					<div>
						{
							this.state.range ?
								<span className="btn btn-portlet-invert"
									style={{ marginRight: 5, display: 'inline', width: 230, color: '#777', borderColor: "var(--outline-color)", height: 35, padding: '4px 10px' }}>
									{moment(this.state.range.startDate).format('DD-MMM-YYYY')} - {moment(this.state.range.endDate).format('DD-MMM-YYYY')}
									<span onClick={() => {
										this.setState((prev) => {
											return ({
												...prev,
												range: false
											})
										})
									}} style={{
										marginRight: 5,
										marginLeft: 10,
										marginTop: 4,
										fontSize: 13,
										cursor: 'pointer'
									}}>×</span>
								</span>
								:
								<ButtonOutline title="Add Date Filter" onClick={() => {
									this.setState(prev => {
										return ({
											...prev,
											dateFilter: true
										})
									})
								}} />
						}
					</div>
				</div>
				<div>
					<NormalTable headerStyle={{
						border: 'none'
					}} 
					sort= {(type) => {
						this.setState((prev)=>{
							return({
								...prev,
								sort: type
							})
						})
					}}
					headers={[
						{key: "Details",name:"Details",weight: 1.75},
						{key: "Itninary",name:"Itninary",weight: 1.75,sort:this.props.sort},
						{key: "Trip Id",name:"Trip Id",weight: 1.75},
						{key:"Actions",name:"Actions",weight: 1.75,style:{textAlign:'right',justifyContent:'flex-end'}}]} data={this.filter(this.state.requests)} pagination={true} mapItem={this.item} />
				</div>
				<Modal
					buttonName="Decline"
					des="Enter the details below to decline the request."
					style={{
						save: {
							width: '100%',
							textAlign: 'center',
							fontSize: 17,
							padding: "12px 20px",
							background: "#7558F6",
							border: "1px solid #7558F6"
						},
					}} show={this.state.decline !== undefined} onSave={this._decline} title="Decline Request"
					close={() => {
						this.setState({
							...this.state,
							decline: undefined
						})
					}}>
					<div>
						<FloatInput title="Enter Remarks" type="text" handleRef={({ ref }) => {
							this._remarks = ref;
						}} />
					</div>
				</Modal>

				<Modal
					buttonName="Close Request"
					des="Enter the details below to close the request."
					style={{
						save: {
							width: '100%',
							textAlign: 'center',
							fontSize: 17,
							padding: "12px 20px",
							background: "#7558F6",
							border: "1px solid #7558F6"
						},
					}} show={this.state.close !== undefined} onSave={this._close} title="Close Request"
					close={() => {
						this.setState({
							...this.state,
							close: undefined
						})
					}}>
					<div>
						<FloatInput title="Enter Remarks" type="text" handleRef={({ ref }) => {
							this._remark = ref;
						}} />
					</div>
				</Modal>
				{
					this.state.dateFilter ?
						<DatePicker1
							apply={(range) => {
								this.setState((prev)=>{
									return({
										...prev,
										dateFilter: false,
										range
									})
								})
							}}
							handle={() => { }}
							onClose={() => {
								this.setState((prev)=>{
									return({
										...prev,
										dateFilter: false
									})
								})
							}}
						/> : null
				}
			</div>
		)
	}

}

export default View