import React from 'react';
import { TitleComponent } from '../../Common/TitleComponent';
import { CitySearch } from '../../Common/CitySearch';
import Moment from 'moment';
import { FloatInput } from '../../../libs/FloatLabelInput';
import { ChooseTraveller } from '../../Common/ChooseTraveller';
import { showToast } from '../../../libs/fToast/ToastCallback';
import { Attachment } from '../../../libs/Attachment';
import { DateTimePickers } from '../../../libs/DateTimePicker';
import { get, post } from '../../../../Model/Network/Config/Axios';





class Index extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);
        this.state = {
            origin: {
                value: '',
                id: ''
            },
            destination: {
                value: '',
                id: ''
            },
            departureDate: new Moment(),
            arrivalDate: new Moment(),
            departureTime: new Moment().hours(0).minute(0),
            arrivalTime: new Moment().hours(0).minute(0),
            employees: [],
            chooseEmployee: false

        }
    }

    mapEmployee = (item, index) => {
        let active = false;
        if (index % 2 === 0) {
            active = true
        }

        return (
            <div key={index} style={{ marginTop: 15, border: '1px solid #eee', borderRadius: 22, background: '#fafafa', padding: '12px 20px', height: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...active ? { marginRight: 15, width: '49%' } : { width: '49%' } }}>
                <p style={{ fontSize: 13, fontWeight: 600 }}>{item.name}</p>
                <p onClick={() => {
                    let list = this.state.employees.filter(val => val.reference !== item.reference);
                    this.setState({
                        ...this.state,
                        employees: list
                    })
                }} style={{
                    fontWeight: 300,
                    cursor: 'pointer'
                }}>Remove</p>
            </div>
        )
    }

    _newBooking = async() => {

        let amount = this._amount.value();
        let id = this._id.value();
        let name = this._cabName.value();
        let operator = this._operator.value();
        let origin = this.state.origin;
        let destination = this.state.destination;
        let departureTime = this._departureTime.getValue();
        let arrivalTime = this._arrivalTime.getValue();
        let employee = this.state.employees
        let tripId = this._tripId.value();

        if (!origin) {
            showToast({
                type: 'error',
                message: 'Please enter valid origin city'
            })
            return;
        }

        if (!destination) {
            showToast({
                type: 'error',
                message: 'Please enter a valid destination city'
            })
            return;
        }

        if (!departureTime) {
            showToast({
                type: 'error',
                message: 'Please enter valid deparutre time'
            })
            return;
        }

        if (!arrivalTime) {
            showToast({
                type: 'error',
                message: 'Please enter valid arrival time'
            })
            return;
        }



        if (!id) {
            showToast({
                type: 'error',
                message: 'Please enter valid id'
            })
            return;
        }

        if (!name) {
            showToast({
                type: 'error',
                message: 'Please enter valid cab name'
            })
            return;
        }

        if (!operator) {
            showToast({
                type: 'error',
                message: 'Please enter valid cab operator'
            })
            return;
        }

        if (!amount) {
            showToast({
                type: 'error',
                message: 'Please enter valid amount'
            })
            return;
        }

        if (employee.length === 0) {
            showToast({
                type: 'error',
                message: 'Please enter a employee details'
            })
            return;
        }

        let guests = employee.map(val => val.email);
        let files = this.attachment.getValue();

        let getTenant;
        await new Promise((res)=>{
            get("luna/tenant", (e, r) => {
                if(r){
                    getTenant=r.tenant;
                }
                res("");
            })
        })

        if(files.length===0 && getTenant==='STELMC'){
            showToast({
                message:'Please Add An Attachment',
                type:'error'
            }) 
            return ;
        }

        let data = {
            id,
            amount: +amount,
            guests,
            cab: {
                name,
                operator
            },
            departure: {
                city: origin.value,
                time: departureTime
            },
            arrival: {
                city: destination.value,
                time: arrivalTime
            },
            files,
            tripId
        }


        post(`/cab/import`, data, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'Cab Ticket created Successfully'
                })
                window.location.reload('/')
            }
            if (e) {
                showToast({
                    type: 'error',
                    message: e.data.error
                })
            }
        })

    }



    render() {
        return (
            <div className="side-container">
                <TitleComponent title="Create Booking" subtitle="Cab" />
                <div style={{ flex: 1, marginBottom: 15, marginTop: 20 }}>
                    <p className="float-p-same">Origin:</p>
                    <div style={{
                        position: 'relative'
                    }}>
                        <CitySearch style={{ height: 39 }} placeholder="Origin" airport={false} city={this.state.origin} onCitySelected={origin => {
                            this.setState({
                                ...this.state,
                                origin
                            })
                        }} />
                    </div>
                </div>
                <div style={{ flex: 1, marginBottom: 8 }}>
                    <p className="float-p-same">Destination:</p>
                    <div style={{
                        position: 'relative'
                    }}>
                        <CitySearch style={{ height: 39 }} placeholder="Destination" airport={false} city={this.state.destination} onCitySelected={destination => {
                            this.setState({
                                ...this.state,
                                destination
                            })
                        }} />
                    </div>
                </div>


                <div style={{
                    display: 'flex',
                    marginBottom: 15,
                    marginTop: 20
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 15
                    }}>

                        <DateTimePickers title="Departure Time"
                            handleRef={ref => {
                                this._departureTime = ref
                            }}
                        />
                    </div>
                    <div style={{
                        flex: 1
                    }}>
                        <DateTimePickers title="Arrival Time"
                            handleRef={ref => {
                                this._arrivalTime = ref
                            }}
                        />
                    </div>
                </div>



                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, marginRight: 15 }}>
                        <FloatInput style={{
                            height: 39
                        }} type="text" title="Id" handleRef={ref => this._id = ref} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <FloatInput style={{
                            height: 39
                        }} type="text" title="Cab Operator" handleRef={ref => this._operator = ref} />
                    </div>
                </div>

                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, marginRight: 15 }}>
                        <FloatInput style={{
                            height: 39
                        }} type="text" title="Cab Name" handleRef={ref => this._cabName = ref} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <FloatInput style={{
                            height: 39
                        }} type="text" title="Amount" handleRef={ref => this._amount = ref} />
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    marginBottom: 8
                }}>
                    <div style={{flex:'0 1 50%', marginRight: 15 }}>
                        <FloatInput style={{ height: 39 }} type="text" title="Trip Id" handleRef={ref => this._tripId = ref} />
                    </div>
                </div>


                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {
                        this.state.employees.map(this.mapEmployee)
                    }
                    <div onClick={() => {
                        this.setState({
                            ...this.state,
                            chooseEmployee: true
                        })
                    }} style={{
                        display: 'flex',
                        marginTop: 15,
                        alignItems: 'center',
                        background: "#fafafa",
                        border: '1px solid #eee',
                        borderRadius: 22,
                        position: 'relative',
                        padding: '12px 20px',
                        width: '49%',
                        cursor: 'pointer',
                        flexDirection: 'row'
                    }}>
                        <p className="label-back" style={{
                            fontWeight: 400,
                            color: "#555555",
                            fontSize: 13,
                            position: 'absolute',
                            top: -8,
                            zIndex: 11,
                            left: 20,
                            marginRight: 10
                        }}>
                            Traveller
                        </p>
                        <p style={{
                            fontWeight: 600,
                            color: "#454545",
                            fontSize: 15,
                        }}>{"Add Traveller"}</p>
                    </div>
                </div>

                <Attachment handleRef={ref => this.attachment = ref} />
                <ChooseTraveller shown={this.state.chooseEmployee} close={() => this.setState({
                    ...this.state,
                    chooseEmployee: false
                })} callback={data => {
                    let arr = this.state.employees.filter(val => val.reference !== data.reference);

                    arr.push(data)
                    this.setState({
                        ...this.state,
                        employees: [...arr],
                        chooseEmployee: false
                    })

                }} />
                <div onClick={this._newBooking} className="btn btn-portlet" style={{
                    marginTop: 50,
                    height: 42,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }} >Create Booking</div>

            </div>
        );
    }
}

export default Index;