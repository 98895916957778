import React, { useRef, useState, useEffect } from 'react'
import { SidePane } from '../../../libs/fSidePane/RightPane'
import axios from '../../../../Model/Network/Config/Axios';
import ButtonOutline from '../../../libs/ButtonOutline';
import { showToast } from '../../../libs/Toast/ToastCallback';
import papa from 'papaparse';
import { DownloadCsv } from '../../../../Utils/Downloader';
import moment from 'moment';
import { StateButton } from '../../Common/StateButton';
import { api } from '../../../../Model/Network/Api/config';

const Item = ({ title, desc }) => {
    return (
        <div style={{
            width: '33%',
            padding: 12
        }}>
            <div style={{
                border: '1px solid var(--border-color)',
                padding: 18,
            }}>
                <p style={{ color: 'var(--text-color)', fontSize: 13 }}>{title}</p>
                <p style={{
                    fontSize: 20, marginTop: 10
                }}>{desc}</p>
            </div>
        </div>
    )
}

const BulkUpload = ({ onClose }) => {
    const FILE = useRef();
    const [state, setState] = useState({ url: undefined, loading: false })
    const [buttonloading, setButtonLoading] = useState(false);
    const [completed, setCompleted] = useState(null);
    const [length, setLength] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [errorCSV, setErrorCSV] = useState([]);

    const MyFilter = (origional) => {
        let temp = [];
        origional.forEach((el) => {
            let err = { ...el };
            let errorMessage = "";
            if (!el.city) errorMessage = errorMessage + "City is missing, ";
            if (!el.hotel_name) errorMessage = errorMessage + "Hotel Name is missing, ";
            if (!el.amount) errorMessage = errorMessage + "Amount is missing, ";
            if (!el.base_amount) errorMessage = errorMessage + "Base Amount is missing, ";
            if (!el['check_in(DD-MM-YYYY)']) errorMessage = errorMessage + "Check In Date is missing, ";
            if (!el['check_out(DD-MM-YYYY)']) errorMessage = errorMessage + "Check Out Date is missing, ";
            // if (!el.address) errorMessage = errorMessage + "Address is missing, ";
            if (!el.guests) errorMessage = errorMessage + "Guests is missing, ";

            if (!errorMessage) {
                temp.push(err);
            } else {
                err.error = errorMessage;
                setErrorCSV(prev => [...prev, err]);
            }
        })
        return temp;
    }

    const handleErrorCSV = (error, data) => {
        let err = { ...data };
        err.error = error.data ? error.data.message : "API Error";
        setErrorCSV(prev => [...prev, err]);
    }

    const downloadErrorCSV = () => {
        let errorList = [];
        let base = Object.keys(errorCSV[0]);
        errorList.push(base);
        errorCSV.forEach((el) => {
            errorList.push(Object.values(el));
        })
        setErrorCSV([])
        DownloadCsv("Error.csv", errorList);
    }


    let upload = () => {
        let file = state.url;
        if (!file) {
            showToast({
                type: 'error',
                message: 'Please select the valid file'
            })
            return;
        }

        papa.parse(file, {
            download: true,
            delimiter: ",",	// auto-detect
            newline: "",
            header: true,
            transformHeader: undefined,
            dynamicTyping: false,
            preview: 0,
            encoding: "",
            worker: false,
            comments: false,
            complete: (result, file) => {
                let final_sheet = []
                final_sheet = MyFilter(result.data)
                console.log('final_sheet', final_sheet)
                if(final_sheet.length === 0) setCompleted(0)
                setButtonLoading(true);
                bulkUpload(final_sheet)
            },
            delimitersToGuess: [',', '\t', '|']
        })
    }

    let bulkUpload = (all_data) => {
        startUpload(all_data);
        setLength(all_data.length);
    }

    let startUpload = async (data) => {
        let count = 0;
        for (const item in data) {
            const res = await init(data[item]);
            count = res + count;
        }
        setCompleted(count)
        setButtonLoading(false);
        setUploading(true);
        if(count === data.length){
            showToast({
                type: 'success',
                message: "Bulk Transaction Update Successfully"
            })
        }else{
            showToast({
                type: 'error',
                message: "Bulk Transaction Update failed"
            })
        }

    }

    useEffect(() => {
        if (completed !==null && errorCSV.length) {
            downloadErrorCSV();
        }
    }, [completed, errorCSV, length])

    let init = async (val) => {
        let temp = { ...val };
        temp.guests = temp.guests.split(',').map(el => el.trim());
        let dates = { in: moment(temp['check_in(DD-MM-YYYY)'], "DD-MM-YYYY").valueOf(), out: moment(temp['check_out(DD-MM-YYYY)'], "DD-MM-YYYY").valueOf() }
        temp['dates'] = dates;
        delete temp['check_in(DD-MM-YYYY)'];
        delete temp['check_out(DD-MM-YYYY)'];
        if (temp['invoice_date(DD-MM-YYYY)']) {
            temp['invoice_date'] = moment(temp['invoice_date(DD-MM-YYYY)'], "DD-MM-YYYY").valueOf();
        }
        delete temp['invoice_date(DD-MM-YYYY)'];
        temp["name"] = temp.hotel_name;
        delete temp.hotel_name
        temp.amount = Number(temp.amount);
        temp.base_amount = Number(temp.base_amount);
        if (temp.igst) temp.igst = Number(temp.igst);
        else temp.igst = 0;
        if (temp.sgst) temp.sgst = Number(temp.sgst);
        else temp.sgst = 0;
        if (temp.cgst) temp.cgst = Number(temp.cgst);
        else temp.cgst = 0;
        if (temp.total_tax) temp.total_tax = Number(temp.total_tax);
        else temp.total_tax = 0;
        if (temp.other_taxes) temp.other_taxes = Number(temp.other_taxes);
        else temp.other_taxes = 0;

        if (temp.id) temp.id = Number(temp.id);
        if (!temp.invoice_no) delete temp.invoice_no
        if (!temp.vendor_code) delete temp.vendor_code
        if (!temp.seller_gstin) delete temp.seller_gstin
        temp["invoice"] = "";
        try {
            let url = api;
            if(url.endsWith('/')) url = url.slice(0,-1);
            await axios.post(`${url}/hotels/import`,temp)
            return 1;
        } catch (error) {
            handleErrorCSV(error,val);
            return 0
        }
    }

    const handleFileSelection = (val) => {
        let file = FILE.current.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            setState(prev => ({ ...prev, loading: true }))
            axios({
                method: 'post',
                url: 'https://file.eka.io/file',
                data: formData,
                headers: {
                    clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
                    clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
                }
            }).then(res => {
                setState(prev => ({ ...prev, url: res.data.url, loading: false }))
            }).catch(err => {
                alert("Unable to upload file")
                setState(prev => ({ ...prev, loading: false }))
            })
        } else {
            setState(prev => ({ ...prev, url: undefined, loading: false }))
        }
    }
    return (
        <SidePane button={false} onClose={onClose}>

            <>
                <div
                    style={{ color: "var(--text-color)", fontWeight: 700, fontSize: "28px", lineHeight: "1.5", marginBottom: "25px" }}
                >
                    Bulk Upload Booking
                </div>
                <hr />
                <section>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                        <label htmlFor='importBooking'>Select File</label>
                        <input id="importBooking" type="file" onChange={handleFileSelection} ref={FILE} style={{
                            height: '100%',
                            width: '100%',
                            padding: '10px 20px',
                            border: '1px solid var(--border-color)'
                        }} />
                    </div>
                    {buttonloading? <StateButton/> :
                    <ButtonOutline style={{ marginTop: 10 }} title='Upload' disabled={!state.url} onClick={upload} />
                    }

                    {
                        uploading ?
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                marginTop: 30
                            }}>
                                <Item title="Total Request" desc={length} />
                                <Item title="Success Request" desc={completed} />
                            </div>
                            :
                            <div style={{
                                marginTop: 30,
                                fontSize: 13
                            }}>
                                <span onClick={() => {
                                    DownloadCsv("HotelBooking.csv",[["guests","amount","id","hotel_name","address","city","check_in(DD-MM-YYYY)","check_out(DD-MM-YYYY)","base_amount","igst","sgst","cgst","total_tax","other_taxes","invoice_no","invoice_date(DD-MM-YYYY)","vendor_code","seller_gstin"]])
                                }} style={{ color: 'var(--primary-color)', cursor: 'pointer', }}>Download Csv</span> for format
                            </div>
                    }
                </section>
            </>

        </SidePane>
    )
}

export default BulkUpload