import { get, patch } from "../../../../../Model/Network/Config/Axios";

class Model {

	constructor() {
		this.Disposables = [];
	}

	destroy = () => {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}

	fetch = (cb) => {
		this.Disposables.push(get(`/requests/all.extended.deleted`, cb))
	}

	delete = (service, id, data, cb) => {
		patch(
			`/${service}/request/${id}/delete`, data, cb
		)
	}

}

export default Model