import React from 'react'

const Search = ({ placeholder, onChange, query, styles }) => {
    return (
        <input style={{
            margin: '10px 0px',
            border: '1px solid var(--outline-color)',
            borderRadius: '5px',
            width: '25%',
            padding: '10px 10px',
            ...styles
        }} type='text' placeholder={placeholder} onChange={onChange} value={query} />
    )
}

export default Search