import React from 'react';
import { TitleComponent } from '../../Common/TitleComponent';
import { FloatInput } from '../../../libs/FloatLabelInput';
import { CitySearch } from '../../Common/CitySearch';
import Moment from 'moment'
import { ChooseTraveller } from '../../Common/ChooseTraveller';
import { handleError, showToast } from '../../../libs/fToast/ToastCallback';
import { Attachment } from '../../../libs/Attachment';
import { DateTimePickers } from '../../../libs/DateTimePicker';
import { post, get } from '../../../../Model/Network/Config/Axios';
import { Modal } from '../../../libs/Modal/Modal';
import BulkUpload from './BulkUpload';
import ButtonOutline from '../../../libs/ButtonOutline';
import TripSearchable from '../../Common/TripSearchable';
import { SearchableSelect } from '../../../libs/Select/SearchableSelec2';

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this); 
        this.state = {
            originCity: {
                id: '',
                value: ''
            },
            destinationCity: {
                id: '',
                value: ''
            },
            arrivalDate: new Moment(),
            departureDate: new Moment(),
            departureTime: new Moment().hours(0).minute(0),
            arrivalTime: new Moment().hours(0).minute(0),
            employees: [],
            chooseEmployee: false,
            invoiceDate: null,
            invoiceToggle:false,
            modal:undefined,
            loading:false,
            trip:undefined,
            itenaries:[],
            filteredItineraries:[],
            selectedItenerary:undefined,
        }
    }

    mapEmployee = (item, index) => {
        let active = false;
        if (index % 2 === 0) {
            active = true
        }

        return (
            <div key={index} style={{ marginTop: 15, border: '1px solid #eee', borderRadius: 22, background: '#fafafa', padding: '12px 20px', height: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...active ? { marginRight: 15, width: '49%' } : { width: '49%' } }}>
                <p style={{ fontSize: 13, fontWeight: 600 }}>{item.name}</p>
                <p onClick={() => {
                    let list = this.state.employees.filter(val => val.reference !== item.reference);
                    this.setState({
                        ...this.state,
                        employees: list
                    })
                }} style={{
                    fontWeight: 300,
                    cursor: 'pointer'
                }}>Remove</p>
            </div>
        )
    }

    _newBooking = () => {

        if(this.state.loading)  return ;

        let originCity = this.state.originCity;
        let destionationCity = this.state.destinationCity;
        let departureTime = this._departureTime.getValue();
        let arrivalTime = this._arrivalTime.getValue();
        let airlineName = this._airlineName.value();
        let pnrNumber = this._pnr.value();
        let employees = this.state.employees;
        let flightCode = this._flightCode.value();
        let flightNumber = this._flightNumber.value();
        let iGst = this._igst.value();
        let sGst = this._sgst.value();
        let cGst = this._cgst.value();
        let base_amount = this._baseAmount.value();
        let invoiceNumber = this._invoiceNumber.value();
        let vendorCode = this._vendorCode.value();
        let sellergstin = this._gstin.value();
        let other_taxes = this._otherTax.value();
        let invoice_date = this._invoiceDate.getValue();
        let tripId = this.state.trip;

        if (!originCity.value) {
            showToast({
                type: 'error',
                message: "Please enter valid origin airport"
            })
            return;
        }

        if (!destionationCity.value) {
            showToast({
                type: 'error',
                message: "Please enter valid destination airport"
            })
            return;
        }   

        if(!base_amount){
            showToast({
                type: 'error',
                message: 'Please Enter Base Amount'
            })
            return;
        }


        if (!departureTime) {
            showToast({
                type: 'error',
                message: "Please enter valid departure Time "
            })
            return;
        }

        if (!arrivalTime) {
            showToast({
                type: 'error',
                message: "Please enter valid arrival Time"
            })
            return;
        }


        if (!airlineName) {
            showToast({
                type: 'error',
                message: "Please enter valid Airline Name"
            })
            return;
        }

        if (!pnrNumber) {
            showToast({
                type: 'error',
                message: "Please enter valid Pnr Number"
            })
            return;
        }
        if (!flightNumber) {
            showToast({
                type: 'error',
                message: 'Please enter the flight number'
            })
            return;
        }

        if(!tripId){
            showToast({
                type: 'error',
                message: 'Please select a trip'
            })
            return;
        }

        if (employees.length === 0) {
            showToast({
                type: 'error',
                message: 'Please select an employee'
            })
            return;
        }

        let guests = employees.map(val => val.email);

        let files = this.attachment.getValue();

        if(!files.length){
            showToast({
                type: 'error',
                message: 'Please add an attachment file'
            })
            return ;
        }

        let invoiceFile = this.invoiceAttachment.getValue().length>0 ? this.invoiceAttachment.getValue()[0]:null;

        let sumOfBase = Number(base_amount)+Number(iGst)+Number(cGst)+Number(sGst)+ (other_taxes?parseFloat(other_taxes):0)

        let data = {
            guests,
            amount: +sumOfBase.toFixed(2),
            pnr: pnrNumber,
            flight: {
                code: flightCode,
                number: flightNumber,
                airline: airlineName,

            },
            departure: {
                airport: originCity.id,
                time: departureTime
            },
            arrival: {
                airport: destionationCity.id,
                time: arrivalTime
            },
            files,
            invoice:invoiceFile,
            base_amount: +base_amount,
            igst: +iGst,
            sgst: +sGst,
            cgst: +cGst,
            total_tax: +(Number(iGst)+Number(sGst)+Number(cGst)),
            other_taxes: other_taxes?parseFloat(other_taxes):0,
        }
        
        if(invoiceNumber) data.invoice_no = invoiceNumber
        if(vendorCode) data.vendor_code = vendorCode
        if(sellergstin) data.seller_gstin = sellergstin
        if(invoice_date) data.invoice_date = Moment(invoice_date).valueOf()
        data.tripDbId=Number(tripId.split('_')[0]);
        if(!this.state.selectedItenerary){
            showToast({
                type:'error',
                message:'Please Select Itenerary To Continue'
            })
            return; 
        }

        data.tripItineraryId=this.state.selectedItenerary.value

        this.setState({
            ...this.state,
            loading:true
        })

        if(tripId){
            data.tripId=tripId.split('_')[1];
        }

        post(`flight/import`, data, (e, r) => { 
            if (r) {
                // getting null as String type 
                if(r.tripId && r.tripId !== "null"){
                    this.setState({
                        ...this.state,
                        modal: r.tripId
                    })
                } else {
                    showToast({
                        type: 'success',
                        message: 'Ticket upload successfully'
                    })
                    window.location.reload('/')
                }
            }else{
                handleError(e)
                this.setState({
                    ...this.state,
                    loading:false
                })
            } 
        })
    }


    calculate=()=>{
        let base_amount = this._baseAmount.value();
        let iGst = this._igst.value();
        let sGst = this._sgst.value();
        let cGst = this._cgst.value();
        let other_taxes = this._otherTax.value();
        let sumOfBase = Number(base_amount)+Number(iGst)+Number(cGst)+Number(sGst)+ (other_taxes?parseFloat(other_taxes):0)
        this._amount.setValue(sumOfBase);
        this.setState({
            ...this.state,
            amount:sumOfBase
        })
    }

    addEmployeeOfTrip=(val)=>{
        if(val && val.employee){
            let employees=[...this.state.employees];
            employees=employees.filter((item)=>{
                if(item && (item.trip || item.reference===val.employee.code)){
                    return false
                }
                return true;
            })
            employees.push({
                email: val.employee.email,
                name: val.employee.name,
                reference: val.employee.code,
                type : "EMPLOYEE",
                trip:true
            })
            this.setState((prev)=>{
                return {
                    ...prev,
                    employees
                }
            })
        }
    }

    render() {

        return (
            <>
            <div className="side-container">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TitleComponent title="Create Booking" subtitle="FLIGHT" />
                    <ButtonOutline title="Bulk Upload" onClick={() => this.setState({ ...this.state, BulkUploadBooking: true })} />
                </div>
                
                <div style={{
                    flex: 1,
                    display: 'flex',
                    marginBottom: 15,
                    marginTop: 20
                }}>
                    <div style={{
                        flex: 1,
                    }}>
                        <p className="float-p-same">Origin:</p>
                        <div style={{
                            position: 'relative'
                        }}>
                            <CitySearch style={{ height: 39 }} placeholder="Origin" airport={true} city={this.state.originCity} onCitySelected={originCity => {
                                this.setState({
                                    ...this.state,
                                    originCity
                                })
                            }} /> 
                        </div>
                    </div>
                </div>
                <div style={{ flex: 1 }}>
                    <p className="float-p-same">Destination:</p>
                    <div style={{
                        position: 'relative'
                    }}>
                        <CitySearch style={{ height: 39 }} placeholder="Destination" airport={true} city={this.state.destinationCity} onCitySelected={destinationCity => {
                            this.setState({
                                ...this.state,
                                destinationCity
                            })
                        }} />
                    </div>
                </div>


                    
  
                <div style={{
                    display: 'flex',
                    marginBottom: 15
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 15
                    }}>
                        <FloatInput onChange={()=>this.calculate()} style={{height: 39 }} type="number" title="Base Amount" handleRef={ref => this._baseAmount = ref} />
                    </div>
                    <div style={{
                        flex: 1
                    }}>
                         <FloatInput required={false} onChange={()=>this.calculate()} style={{height: 39 }} type="number" title="IGST Amount" handleRef={ref => this._igst = ref} />
                    </div>
                </div>

                  
                <div style={{
                    display: 'flex',
                    marginBottom: 15
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 15
                    }}>
                        <FloatInput required={false} onChange={()=>this.calculate()} style={{height: 39 }} type="number" title="CGST Amount" handleRef={ref => this._cgst = ref} />
                    </div>
                    <div style={{
                        flex: 1
                    }}>
                         <FloatInput required={false} onChange={()=>this.calculate()} style={{height: 39 }} type="number" title="SGST Amount" handleRef={ref => this._sgst = ref} />
                    </div>
                </div> 

                <div style={{
                    display: 'flex',
                    marginBottom: 15
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 15
                    }}>
                        <FloatInput required={false} onChange={()=>this.calculate()} style={{
                        height: 39
                    }} type="number" title="Other Tax" handleRef={ref => this._otherTax = ref} />
                    </div>
                    <div style={{
                        flex: 1
                    }}>
                        <FloatInput disabled style={{
                        height: 39
                    }} type="number" title="Total Amount" handleRef={ref => this._amount = ref} />
                    </div>
                </div> 

                <div style={{ display: 'flex', marginBottom: 15,alignItems:'center'}}>
                    <div style={{ flex: 1, marginRight: 15 }}>
                        <FloatInput
                            required={false}
                            style={{ height: 39 }}
                            type="text"
                            title="Invoice Number"
                            handleRef={ref => this._invoiceNumber = ref}
                        />
                    </div>
                    <div style={{ flex: 1, }}>
                    <DateTimePickers title="Invoice Date"
                            defaultTimeNull={true}
                            handleRef={ref => {
                                this._invoiceDate = ref
                            }}
                        />
                    </div>
                </div>
         
                <div style={{ display: 'flex', marginBottom: 15 }}>
                    <div style={{ flex: 1, marginRight: 15 }}>
                        <FloatInput
                            required={false}
                            style={{ height: 39 }} t
                            ype="text"
                            title="Seller GSTIN"
                            handleRef={ref => this._gstin = ref}
                        />
                    </div>
                    <div style={{ flex: 1, }}>
                        <FloatInput
                            required={false}
                            style={{ height: 39 }}
                            type="text"
                            title="Vendor Code"
                            handleRef={ref => this._vendorCode = ref}
                        />
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    marginBottom: 15
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 15
                    }}>



                        <DateTimePickers title="Departure Time"
                            handleRef={ref => {
                                this._departureTime = ref
                            }}
                        />
                    </div>
                    <div style={{
                        flex: 1
                    }}>
                        <DateTimePickers title="Arrival Time"
                            handleRef={ref => {
                                this._arrivalTime = ref
                            }}
                        />
                    </div>
                </div>

                <div style={{
                    display: 'flex'
                }}>
                    <div style={{ flex: 1, marginRight: 15 }}>
                        <FloatInput style={{
                            height: 39
                        }} type="text" title="Airline Name" handleRef={ref => this._airlineName = ref} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <FloatInput style={{
                            height: 39
                        }} type="text" title="Pnr Number" handleRef={ref => this._pnr = ref} />
                    </div>
                </div>

                <div style={{
                    display: 'flex'
                }}>
                    <div style={{ flex: 1, marginRight: 15 }}>
                        <FloatInput required={false} style={{
                            height: 39
                        }} type="text" title="Flight Code" handleRef={ref => this._flightCode = ref} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <FloatInput style={{
                            height: 39
                        }} type="text" title="Flight Number" handleRef={ref => this._flightNumber = ref} />
                    </div>
                </div>
                <div style={{
                    display: 'flex'
                }}>
                    <div style={{flex:'0 1 50%', marginRight: 15 }}>
                        <TripSearchable addEmployeeOfTrip={this.addEmployeeOfTrip} onSelect={trip=>{
                            this.setState((prev)=>({...prev,trip:trip,selectedItenerary:undefined,city:{}, originCity:{value:'',id:''}, destinationCity:{value:'',id:''}}))
                            this._arrivalTime.setValue();
                            this._departureTime.setValue();
                            get(`trip/${trip.split('_')[1]}/trip.itinerary`,(e,r)=>{ 
                                if(r){
                                    let items=r.itineraries.filter((item)=>{
                                        if(item && item.travel && item.travel.toLowerCase().includes("flight")){
                                            return true;
                                        }
                                        return false;
                                    }).map(item=>({label:`${item.current.name}`,value:item.id,des:`End Date : ${item.end ? item.end.date : '-'}`,des1:`Travel : ${item.travel}, Stay : ${item.stay}`,data:item}))
                                    this.setState((prev)=>({...prev,
                                        itenaries:items,
                                        filteredItineraries:items
                                    }))
                                }
                            })
                        }} />
                    </div>
                    <div style={{ flex: 1 }} >
                        {
                            this.state.trip ?

                            <SearchableSelect
                                items={this.state.filteredItineraries}
                                onChange={(val)=>{
                                    if(val.length){
                                        const filteredData=this.state.itenaries.filter(item=>JSON.stringify(item).includes(val));
                                        this.setState((prev)=>({...prev,filteredItineraries:filteredData}));
                                    }else{
                                        this.setState(prev=>({...prev,filteredItineraries:this.state.itenaries}));
                                    }
                                }}
                                label={"Itenararies"}
                                placeholder={"Select Itenerary"} 
                                select={async (val)=>{
                                    const data={selectedItenerary:val,city:{value:val.label}};
                                    if(val.data.start)  this._departureTime.setValue(val.data.start.timestamp);
                                    else this._departureTime.setValue();
                                    if(val.data.end)    this._arrivalTime.setValue(val.data.end.timestamp);
                                    else this._arrivalTime.setValue();
                                    if(val && val.data && val.data.current && val.data.current.airport){
                                        await new Promise((res)=>{
                                            const startAirport=val.data.current.airport;
                                            get(`/search/airports`, (e, r) => {
                                                if (r && r.airports && r.airports.length) {
                                                    const item=r.airports[0];
                                                    data.destinationCity={
                                                        value: `${item.airport},${item.name}`,
                                                        id: item.code,
                                                        country: item.country
                                                    }
                                                }
                                                res("");
                                            }, { code: startAirport }) 
                                        })
                                    }
                                    if(val && val.data && val.data.last && val.data.last.airport){
                                        await new Promise((res)=>{                            
                                            const endAirport=val.data.last.airport;
                                            get(`/search/airports`, (e, r) => {
                                                if (r && r.airports && r.airports.length) {
                                                    const item=r.airports[0];
                                                    data.originCity={
                                                        value: `${item.airport},${item.name}`,
                                                        id: item.code,
                                                        country: item.country
                                                    }
                                                }
                                                res("");
                                            }, { code: endAirport })
                                        })
                                    }
                                    this.setState(prev=>({...prev,...data}));
                                }} 
                                value={this.state.selectedItenerary}
                            />

                            :  null
                        }
                    </div>
                </div>

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {
                        this.state.employees.map(this.mapEmployee)
                    }
                    <div onClick={() => {
                        this.setState({
                            ...this.state,
                            chooseEmployee: true
                        })
                    }} style={{
                        display: 'flex',
                        marginTop: 15,
                        alignItems: 'center',
                        background: "#fafafa",
                        border: '1px solid #eee',
                        borderRadius: 22,
                        position: 'relative',
                        padding: '12px 20px',
                        width: '49%',
                        cursor: 'pointer',
                        flexDirection: 'row'
                    }}>
                        <p className="label-back" style={{
                            fontWeight: 400,
                            color: "#555555",
                            fontSize: 13,
                            position: 'absolute',
                            top: -8,
                            zIndex: 11,
                            left: 20,
                            marginRight: 10
                        }}>
                            Traveller
                        </p>
                        <p style={{
                            fontWeight: 600,
                            color: "#454545",
                            fontSize: 15,
                        }}>{"Add Traveller"}</p>
                    </div>
                </div>
                
                <Attachment
                    handleRef={ref => this.attachment = ref}
                />
                <Attachment
                    title='Add Invoice'
                    handleRef={ref => this.invoiceAttachment=ref}
                    singleFileAttachment={true}
                />

                <ChooseTraveller shown={this.state.chooseEmployee} close={() => this.setState({
                    ...this.state,
                    chooseEmployee: false
                })} callback={data => {
                    let arr = this.state.employees.filter(val => val.reference !== data.reference);
                    if (this.state.employees.length > 7) {
                        showToast({
                            type: 'error',
                            message: 'Maximum 7 passenger added in single booking'
                        })
                    } else {
                        arr.push(data)
                        this.setState({
                            ...this.state,
                            employees: [...arr],
                            chooseEmployee: false
                        })
                    }
                }} />
                <div onClick={this._newBooking} className="btn btn-portlet" style={{
                    marginTop: 50,
                    height: 42,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }} >{ this.state.loading ? <div className="loader" />: 'Create Booking'}</div>
            </div>

            <Modal 
                show={this.state.modal}
                title='Ticket Uploaded Successfully'
                des={`Ticket Uploaded Successfully to ${this.state.modal}`}
                close={() => {this.setState({ ...this.state, modal: undefined });window.location.reload('/')}}
                buttonName='Ok'
                onSave={() => {this.setState({ ...this.state, modal: undefined });window.location.reload('/')}}
                style={{
                    save: {
                        width: '100%',
                        height: '45px',
                        display: 'flex',
                        justifyContent: 'center',
                        textTransform: 'uppercase',
                        alignItems: 'center'
                    }
                }}
            />
            {this.state.BulkUploadBooking && <BulkUpload onClose={()=>this.setState({...this.state,BulkUploadBooking:false})}/>}
            </>
        );
    }
}

export default Index;