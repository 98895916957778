import React from 'react';
import './StatusChip.css'

function StatusChip({ status, className, style, linear = false }) {
    let value;
    if (status) {
        value = status.toLowerCase();
    } else {
        value = ''
    }
    let flag = 'pending';

    if (value.includes('with') || value.includes('finance pending') || value.includes('approval pending') || value.includes('unsubmitted')) {
        flag = 'approval'
    } else if (value.includes('pending')) {
        flag = 'pending'
    } else if (value.includes('settled') || value.includes('completed') || value.includes('approved') || value.includes('valid') || value.includes('active') || value.includes('success') || value.includes('submitted')) {
        flag = 'success'
    } else if (value.includes('duplicate') || value.includes('failed') || value.includes('declined') || value.includes('rejected') || value.includes('invalid') || value.includes('resubmission')) {
        flag = 'failed'
    } else if (value.includes('disabled') || value.includes('inactive')) {
        flag = 'disabled'
    } else if (value.includes('cancelled') || value.includes('violation')) {
        flag = "cancelled"
    }

    if (linear) {
        return <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }} >
            <div className={`chip-circle ${flag}`} />
            <div style={{
                ...style,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                cursor: 'pointer',
            }} className={`linear ${flag}-text ${className}`}>{status}</div>
        </div>;
    }


    return <div data-tooltip-content={status} data-tooltip-id="wrapper" 
    style={{
        ...style,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
    }}
        className={`status-chip  ${flag} ${className}`}>{status}</div>;
}

export default StatusChip;