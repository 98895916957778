import React from 'react'
import { Route, Switch } from 'react-router-dom';

import SearchFlights from './SearchFlights'
import PollingFlights from './PollingFlights'
import Bookings from './Bookings'
import FlightRefund from './FlightRefund'
import { Sidebar } from "../Common/Sidebar";
import Support from '.././CommonPages/Support';
import BookFlight from './BookFlight';
import AllSupport from '../CommonPages/AllSupport';
import CreateBooking from './CreateBooking';
import CreateBookingStelmc from './CreateBookingStelmc';
import Offline from './OfflineBooking';

import ActiveSearchIcon from './assets/search.png'
import InActiveSearchIcon from './assets/search1.png'
import ActiveBookingsIcon from './assets/bookings.png'
import InActiveBookingsIcon from './assets/bookings1.png'

import ActiveRefundIcon from './assets/icon1.png'
import InactiveRefundIcon from './assets/icon1a.png'
import ActiveWorkflowIcon from './assets/icon5a.png'
import InactiveWorkflowIcon from './assets/icon5.png'
import { get } from "../../../Model/Network/Config/Axios";
import { BookingRequests } from "../CommonPages/BookingRequests";
import ViewTicket from './ViewTicket';
import Search from "stringquery";
import { LunaPopUp } from "../Common/Luna";
import { PageLoader } from '../../libs/Loader/PageLoader';
import CancellationRequests from './CancellationRequests';

class index extends React.Component {

	state = {
		bookings: 0,
		requestId: undefined,
		tenant:undefined,
		manualCancellationFlow:false,
		pendingCancellationRequests:0
	}

	fetchCancellationRequests=()=>{
		get("/flight/flightCancellationRequests/all",(e,r)=>{
			if(r){
				if(r && r.rechargeRequests){
					let pendingRequests=[]
					r.rechargeRequests.forEach((item)=>{
						if(item.status==="PENDING"){
							pendingRequests.push(item)
						}
					})
					this.setState((prev)=>{
						return {
							...prev,
							pendingCancellationRequests:pendingRequests.length
						}
					})
				}
			}
		})
	}

	componentDidMount() {
		this._interval = setInterval(this.poll, 10000);
		this.init()
		this.poll()
	}

	init=()=>{
		get(`/config/counts`,(e,r)=>{
			if(r && r.manualCancellationFlow){
				this.setState((prev)=>{
					return {...prev,manualCancellationFlow:r.manualCancellationFlow}
				})
				this.fetchCancellationRequests()
			}
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.location && this.props.location.search) {
			const params = Search(this.props.location.search);
			if (params.requestId && this.state.requestId !== params.requestId) {
				this.setState({
					...this.state,
					requestId: params.requestId
				})
			}
			if (!params.requestId && this.state.requestId) {
				this.setState({
					...this.state,
					requestId: undefined
				})
			}
		} else if (this.state.requestId) {
			this.setState({
				...this.state,
				requestId: undefined
			}) 
		}
	}

	componentWillUnmount() {
		if (this._interval)
			clearInterval(this._interval)
	}

	poll = () => {
		get("luna/tenant", (e, r) => {
			this.setState({...this.state, tenant: r.tenant})
		})
		get(`/flight/counts`, (e, r) => {
			if (r) {
				this.setState({
					...r
				})
			}
		})
	}


	render() {

		if(!this.state.tenant)	return <PageLoader />;

		return (
			<div id="fintrip-container" style={{
				flex: 1
			}}>
				<Sidebar history={this.props.history} location={this.props.location} title="Flights" data={[
					{
						title: "Overview",
						items: [
							{
								title: "Search Flights",
								inactive: ActiveSearchIcon,
								active: InActiveSearchIcon,
								path: "/app/flights/search",
								link: '/app/flights/search?type=search',
								includes: true
							},
							{
								title: "Bookings",
								active: ActiveBookingsIcon,
								inactive: InActiveBookingsIcon,
								path: "/app/flights/bookings",
								includes: true
							},
							...(this.state.manualCancellationFlow)?[{
								title: (this.state && this.state.pendingCancellationRequests)? `Cancellation Requests (${this.state.pendingCancellationRequests})` : "Cancellation Requests",
								active: InactiveWorkflowIcon,
								inactive: ActiveWorkflowIcon,
								path: "/app/flights/cancellationRequests",
								includes: true,
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							}]:[]
						]
					},
					{
						title: "Workflow",
						items: [
							{
								title: this.state.bookings > 0 ? `Booking Requests (${this.state.bookings})` : "Booking Requests",
								active: InactiveWorkflowIcon,
								inactive: ActiveWorkflowIcon,
								path: "/app/flights/requests",
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							},

						]
					},
					{
						title: 'Offline Booking',
						items: [
							{
								title: 'Import Booking',
								active: InactiveWorkflowIcon,
								inactive: ActiveWorkflowIcon,
								path: '/app/flights/create',
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							},
							{
								title: 'Offline Bookings',
								active: ActiveBookingsIcon,
								inactive: InActiveBookingsIcon,
								path: '/app/flights/offline',
							}
						]
					},
					{
						title: "Help & Support",
						items: [
							{
								title: "Refunds",
								active: ActiveRefundIcon,
								inactive: InactiveRefundIcon,
								path: "/app/flights/refunds",
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							}
						]
					}
				]} />
				<div style={{ flex: 1, overflowY: 'auto' }}>
					<Switch>
						<Route exact={true} component={SearchFlights} path="/app/flights/search" />
						<Route exact={true} component={PollingFlights} path="/app/flights/search/polling/:pollingId" />
						<Route component={BookFlight} path="/app/flights/book" />
\						<BookingRequests sort={true} all={true} exact={true} path="/app/flights/requests" history={this.props.history} service="flight" />
						<Support exact={true} path="/app/flights/support" history={this.props.history} service="flights" />
						<Route component={FlightRefund} path="/app/flights/refunds" />
						<Route component={Bookings} path="/app/flights/bookings" />
						<Route component={ViewTicket} path="/app/flights/tickets/:id" />
						<Route component={AllSupport} path="/app/flights/support/:id" />
						<Route component={ this.state.tenant==='STELMC' || this.state.tenant==='KARANDEVENV' ? CreateBookingStelmc :CreateBooking} path="/app/flights/create" />
						<Route component={Offline} path="/app/flights/offline" />
						<Route component={CancellationRequests} path="/app/flights/cancellationRequests" />
					</Switch>
				</div>
				{
					this.state.requestId ? <LunaPopUp requestId={this.state.requestId} /> : undefined
				}
			</div>
		)
	}

}

export default index
