import React from 'react';
import { TableActions } from '../../libs/Table/TableAction';
import { KeyValue } from '../Common/KeyValue';
import { LunaPopUp } from '../Common/Luna';
import moment from 'moment';
import { COUNTRIES } from '../../libs/Countries';

class VisaRequestMap extends React.Component {

    render() {
        let { item } = this.props;
        let actions = [];
        actions.push({
            name: 'View',
            click: () => { }
        })
        if (item.status === 'PENDING') {
            actions.push({
                name: 'Close',
                click: () => { }
            })
        }
        const findCountryFromCode=(code)=>{
            let country;
            COUNTRIES.some(item=>{
                if(item.value===code){
                    country=item.label;
                }
                return country
            })
            return country;
        }

        return (
            <div>
                <div className="fintrip-department-item table-item" style={{
                    borderBottom: "1px solid #dedede",
                    display: 'flex',
                    cursor: 'pointer',
                    flexDirection: 'row',
                    padding: '12px 0'
                }}>
                    <div style={{ flex: 1, alignItems: 'center' }} className="linear-layout horizontal">
                        <div>
                            <img src={item.employee.logo} alt="employee" style={{
                                width: 30, height: 30, borderRadius: 15,
                                marginRight: 8
                            }} />
                        </div>
                        <div>
                            <p className="top">{item.employee.name}</p>
                            <p className="bottom">{item.employee.email}</p>
                            <p className="bottom">{item.employee.code}</p>
                        </div>
                    </div>
                    <div style={{
                        flex: 1
                    }}>
                        <p style={{
                            fontWeight: 700,
                            fontSize: 14,
                            textAlign: 'center'
                        }} className="top">
                            {item.requestId}
                        </p>
                        <p style={{fontSize:12, fontWeight:400, textAlign: 'center'}} >
                            {moment(Number(item.travelDate)).format('DD-MM-YYYY')}
                        </p>
                        <p style={{fontSize:12, fontWeight:400, textAlign: 'center'}} >
                            Destination : {findCountryFromCode(item.destination)}
                        </p>
                    </div>
                    <div style={{ flex: 1.4 }} className="right-actions">
                        <TableActions items={actions} />
                        <p onClick={() => {
                            if (this.props.open && this.props.open === item.requestId) {
                                this.props.onCloseOpen()
                            } else {
                                this.props.onSetOpen(item.requestId);
                            }
                        }} className="hoverable">{this.props.open === item.requestId ? "Close" : "View"}</p>
                        <p onClick={() => {
                            if(this.props.isOpenRequest){
                                window.open(`http://dice.enterprise.atlys.com/redirect?source=dice&request_id=${item.requestId}`,'_blank');
                            }else{
                                window.open(item.invoicePdf, '_blank');
                            }
                        }} className="hoverable">{this.props.isOpenRequest ? "Apply now" : "Download Invoice"}</p>
                    </div>

                </div>
                {
                    this.props.open === item.requestId ?
                        <div style={{
                            backgroundColor: '#f2f2f2',
                            padding: 20
                        }}>
                            <div style={{
                                display: 'flex',
                                flex: 1
                            }}>
                                <KeyValue title="Citizenship" value={findCountryFromCode(item.citizenship)} />
                                <KeyValue title="Residence" value={findCountryFromCode(item.residence)} />
                                <KeyValue title="Destination" value={findCountryFromCode(item.destination)} />

                            </div>
                            <div style={{
                                display: 'flex',
                                flex: 1,
                                marginTop: 10
                            }}>
                                <KeyValue title="Estimated Time of Arrival" value={moment(Number(item.eta)).format('DD-MM-YYYY hh:mm a')} />
                                <KeyValue title="Length Of Stay (In Days)" value={item.data.stayLength} />
                                <KeyValue title="Amount" value={`${item.data.currency || "INR"} ${item.amount || item.data.fee}`} />
                            </div>
                        </div> : null
                }

                {
                    this.props.requestId ? <LunaPopUp
                        requestId={this.props.requestId} /> : null

                }
            </div>
        )
    }
}

export default VisaRequestMap;