import React from 'react';
import {TitleComponent} from '../../Common/TitleComponent';
import {Route,Switch} from  'react-router-dom';
import TopBar from '../../Common/TopBar';
import { get } from '../../../../Model/Network/Config/Axios';
import { handleError } from '../../../libs/fToast/ToastCallback';
import CancellationRequest from '../../Home/CancellationRequests/CancellationRequest/CancellationRequest';


class Index extends React.Component {

    state = {
		pendingRequests : [],
		pastRequests: [],
		declinedRequests: []
    }

	init=()=>{
		get("/train/trainCancellationRequests/all",(e,r)=>{
			if(r){
				if(r && r.rechargeRequests){
					let pendingRequests=[],pastRequests=[],declinedRequests=[];
					r.rechargeRequests.forEach((item)=>{
						if(item.status==="PENDING"){
							pendingRequests.push(item)
						}
						else if(item.status==='DECLINED'){
							declinedRequests.push(item);
						}else {
							pastRequests.push(item)
						}
					})
					this.setState({
						pendingRequests,
						pastRequests,
						declinedRequests
					})
				}
			}
			else if(e){
				handleError(e);
			}
		})
	}

	componentDidMount(){
		this.init()
	}



    render() {
		let route = this.props.location.pathname;
        return(
			<div className = "side-container">
				<div style = {{display:'flex',justifyContent:'space-between'}}>
					<TitleComponent subtitle = "All" title = "Cancellation Requests" />
				</div>
				<TopBar {...this.props} item = {[
					{
						path:"/app/train/cancellationRequests",
						active:route === "/app/train/cancellationRequests",
						name:'Pending Requests'
					},
					{
						path:"/app/train/cancellationRequests/past",
						active:route === "/app/train/cancellationRequests/past",
						name:'Past Requests'
					},
					{
						path:"/app/train/cancellationRequests/declined",
						active:route === "/app/train/cancellationRequests/declined",
						name:'Declined Requests'
					},
				]} />
				<Switch>
					<Route path = "/app/train/cancellationRequests" render= {()=><CancellationRequest key={'Pending'} init={this.init} requests={this.state.pendingRequests}/>} exact />
					<Route path = "/app/train/cancellationRequests/past" render= {()=><CancellationRequest key={'Past'} past={true} init={this.init} requests={this.state.pastRequests}/>}/>
					<Route path = "/app/train/cancellationRequests/declined" render= {()=><CancellationRequest key={'Declined'} past={true} init={this.init} requests={this.state.declinedRequests}/>} />
				</Switch>
				
            </div>
        )
    }
}

export default Index;