import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Sidebar } from '../Common/Sidebar'
import ActiveWorkflowIcon from '../Travellers/assets/icon5a.png';
import InactiveWorkflowIcon from '../Travellers/assets/icon5.png';
import TripList from './TripList';

const Trip = (props) => {
    return (
		<div id="fintrip-container" style={{
			flex: 1
		}}>
			<Sidebar history={props.history} location={props.location} title="Trips" data={[

				{
					title: 'Trips',
					items: [
						{
							title: 'Upcoming Trip',
							active: InactiveWorkflowIcon,
							inactive: ActiveWorkflowIcon,
							path: '/app/trip',
							style: {
								width: 42,
								height: 42,
								marginLeft: -8,
								marginRight: 8,
								marginBottom: -6,
								marginTop: -6
							}
						},
						{
							title: 'Past Trip',
							active: InactiveWorkflowIcon,
							inactive: ActiveWorkflowIcon,
							path: '/app/trip/past',
							style: {
								width: 42,
								height: 42,
								marginLeft: -8,
								marginRight: 8,
								marginBottom: -6,
								marginTop: -6
							}
						},
                        {
							title: 'Deleted Trip',
							active: InactiveWorkflowIcon,
							inactive: ActiveWorkflowIcon,
							path: '/app/trip/deleted',
							style: {
								width: 42,
								height: 42,
								marginLeft: -8,
								marginRight: 8,
								marginBottom: -6,
								marginTop: -6
							}
						}
					]
				},
			]} />
			<div style={{ flex: 1, overflowY: 'auto' }}>
				<Switch>
                    <Route path="/app/trip/deleted" render={() => <TripList api="/trip/deleted" title='Deleted' />} />
					<Route path="/app/trip/past" render={() => <TripList key="Past" status="Past" api="/trip" title='Past' />}/>
					<Route path="/app/trip" render={() => <TripList key="Upcoming" status="Upcoming" api="/trip" title='Upcoming' />}/>
				</Switch>
			</div>
		</div>
	)
}

export default Trip