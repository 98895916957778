import React from 'react'
import { Route, Switch } from 'react-router-dom';

import SearchHotels from './SearchHotels'
import PollingHotel from './PollingHotel'
import BookHotel from './BookHotel'
import HotelDetails from './HotelDetails'
import Bookings from './Bookings';
import HotelRefund from './HotelRefund';
import ViewTicket from './ViewTicket';
import Support from '../CommonPages/Support';
import CreateBooking from './CreateBooking';
import CreateBookingStelmc from './CreateBookingStelmc';

import { Sidebar } from "../Common/Sidebar";
import AllSupport from '../CommonPages/AllSupport';

import ActiveSearchIcon from './assets/search.png'
import InActiveSearchIcon from './assets/search1.png'
import ActiveBookingsIcon from './assets/bookings.png'
import InActiveBookingsIcon from './assets/bookings1.png'

import ActiveRefundIcon from './assets/icon1.png'
import InactiveRefundIcon from './assets/icon1a.png'
import ActiveWorkflowIcon from './assets/icon5a.png'
import InactiveWorkflowIcon from './assets/icon5.png'
import HotelActive from './assets/hotelA.png';
import HotelInactive from './assets/hotelI.png';
import { get } from "../../../Model/Network/Config/Axios";
import { BookingRequests } from "../CommonPages/BookingRequests";
import Offline from './Offline';
import {LunaPopUp} from "../Common/Luna";
import Search from "stringquery";
import { PageLoader } from '../../libs/Loader/PageLoader';
import CancellationRequests from './CancellationRequests';

class index extends React.Component {


	state = {
		bookings: 0,
		requestId: undefined,
		tenant:undefined,
		manualCancellationFlow:false,
		pendingCancellationRequests:0
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.location && this.props.location.search) {
			const params = Search(this.props.location.search);
			if (params.requestId && this.state.requestId !== params.requestId) {
				this.setState({
					...this.state,
					requestId: params.requestId
				})
			}
			if (!params.requestId && this.state.requestId) {
				this.setState({
					...this.state,
					requestId: undefined
				})
			}
		} else if (this.state.requestId) {
			this.setState({
				...this.state,
				requestId: undefined
			})
		}
	}

	fetchCancellationRequests=()=>{
		get("/hotels/hotelCancellationRequests/all",(e,r)=>{
			if(r){
				if(r && r.rechargeRequests){
					let pendingRequests=[]
					r.rechargeRequests.forEach((item)=>{
						if(item.status==="PENDING"){
							pendingRequests.push(item)
						}
					})
					this.setState((prev)=>{
						return {
							...prev,
							pendingCancellationRequests:pendingRequests.length
						}
					})
				}
			}
		})
	}

	componentDidMount() {
		this._interval = setInterval(this.poll, 10000);
		this.init()
		this.poll()
	}
	
	init=()=>{
		get(`/config/counts`,(e,r)=>{
			if(r && r.manualCancellationFlow){
				this.setState((prev)=>{
					return {...prev,manualCancellationFlow:r.manualCancellationFlow}
				})
				this.fetchCancellationRequests()
			}
		})
	}

	componentWillUnmount() {
		if (this._interval)
			clearInterval(this._interval)
	}

	poll = () => {
		get(`/hotels/counts`, (e, r) => {
			if (r) {
				this.setState({
					...r
				})
			}
		})
		get("luna/tenant", (e, r) => {
			this.setState({...this.state, tenant: r.tenant})
		})
	}

	render() {

		if(!this.state.tenant)	return <PageLoader />;

		return (
			<div id="fintrip-container" style={{
				flex: 1
			}}>
				<Sidebar history={this.props.history} location={this.props.location} title="Hotels" data={[
					{
						title: "Overview",
						items: [
							{
								title: "Search Hotel",
								inactive: ActiveSearchIcon,
								active: InActiveSearchIcon,
								path: "/app/hotel/search"
							},
							{
								title: "Bookings",
								active: ActiveBookingsIcon,
								inactive: InActiveBookingsIcon,
								path: "/app/hotel/bookings",
								includes: true
							},
							...(this.state.manualCancellationFlow)?[{
								title: (this.state && this.state.pendingCancellationRequests)? `Cancellation Requests (${this.state.pendingCancellationRequests})` : "Cancellation Requests",
								active: InactiveWorkflowIcon,
								inactive: ActiveWorkflowIcon,
								path: "/app/hotel/cancellationRequests",
								includes: true,
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							}]:[]
						]
					},
					{
						title: "Workflow",
						items: [
							{
								title: this.state.bookings > 0 ? `Booking Requests (${this.state.bookings})` : "Booking Requests",
								active: InactiveWorkflowIcon,
								inactive: ActiveWorkflowIcon,
								path: "/app/hotel/requests",
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							}
						]
					},
					{
						title: 'Offline Booking',
						items: [
							{
								title: 'Import Booking',
								active: HotelActive,
								inactive: HotelInactive,
								path: "/app/hotel/create",
							},
							{
								title: 'Offline Booking',
								active: ActiveBookingsIcon,
								inactive: InActiveBookingsIcon,
								path: '/app/hotel/offline',
								includes: true
							}
						]
					},
					{
						title: "Help & Support",
						items: [
							{
								title: "Refunds",
								active: ActiveRefundIcon,
								inactive: InactiveRefundIcon,
								path: "/app/hotel/refunds",
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							},
						]
					}
				]} />
				<div style={{ flex: 1 }}>
					<Switch>
						<Route component={SearchHotels} exact={true} path="/app/hotel/search" />
						<Route component={PollingHotel} exact={true} path="/app/hotel/search/polling/:pollingId" />
						<Route component={ViewTicket} path="/app/hotel/tickets/:id" exact />
						<Route component={BookHotel} path="/app/hotel/book" />
						<Route component={HotelDetails} path="/app/hotel/details" />
						<BookingRequests sort={true} all={true} exact={true} path="/app/hotel/requests" history={this.props.history} service="hotels" />
						<Support exact={true} path="/app/hotel/support" history={this.props.history} service="hotels" />
						<Route component={Bookings} path="/app/hotel/bookings" />
						<Route component={HotelRefund} path="/app/hotel/refunds" />
						<Route component={AllSupport} path="/app/hotel/support/:id" />
						<Route component={this.state.tenant==='STELMC' || this.state.tenant==='KARANDEVENV' ? CreateBookingStelmc : CreateBooking } path="/app/hotel/create" />
						<Route component={Offline} path="/app/hotel/offline" />
						<Route component={CancellationRequests} path="/app/hotel/cancellationRequests" />
					</Switch>
				</div> 
				{ 
					this.state.requestId ? <LunaPopUp requestId={this.state.requestId} /> : undefined
				}
			</div>
		)
	}

}

export default index
