import React from 'react';
import Props from 'prop-types'
import 'react-dates/initialize';
import {SingleDatePicker} from 'react-dates';
import UUID from 'uuid';

class DatesCompo extends React.Component {


	state = {
		focused : false
	}

	
	render() {
		return(
			<div>
				<SingleDatePicker
					disabled={Boolean(this.props.disabled)}
					withPortal
					numberOfMonths = {1}
					displayFormat = "DD MMM YYYY"
					date={this.props.selectDate}
					onDateChange={this.props.handleDate}
					focused={this.state.focused}
					onFocusChange={({ focused }) => this.setState({ focused })}
					id={this.props.id?this.props.id:UUID.v4()}
					isOutsideRange={()=>false}
				/>
			</div>
		)
	}
}

DatesCompo.propTypes={
	handleDate:Props.func.isRequired,
	id:Props.string
}

export {
	DatesCompo
}