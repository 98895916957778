import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Sidebar } from '../Common/Sidebar'
import ActiveWorkflowIcon from '../Travellers/assets/icon5a.png';
import InactiveWorkflowIcon from '../Travellers/assets/icon5.png';
import VisaRequests from './VisaRequests';

function index(props) {
	return (
		<div id="fintrip-container" style={{
			flex: 1
		}}>
			<Sidebar history={props.history} location={props.location} title="Visa" data={[

				{
					title: 'Visa Requests',
					items: [
						{
							title: 'Booking Request',
							active: InactiveWorkflowIcon,
							inactive: ActiveWorkflowIcon,
							path: '/app/visa',
							style: {
								width: 42,
								height: 42,
								marginLeft: -8,
								marginRight: 8,
								marginBottom: -6,
								marginTop: -6
							}
						}
					]
				}
			]} />
			<div style={{ flex: 1, overflowY: 'auto' }}>
				<Switch>
					<Route component={VisaRequests} path="/app/visa" />
				</Switch>
			</div>
		</div>
	)
}

export default index