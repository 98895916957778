export const COUNTRIES = [
    { label: 'Afghanistan', value: 'AF', currency: 'AFN', phoneCode: '+93' },
    { label: 'Albania', value: 'AL', currency: 'ALL', phoneCode: '+355' },
    { label: 'Algeria', value: 'DZ', currency: 'DZD', phoneCode: '+213' },
    { label: 'American Samoa', value: 'AS', currency: 'USD', phoneCode: '+1-684' },
    { label: 'Andorra', value: 'AD', currency: 'EUR', phoneCode: '+376' },
    { label: 'Angola', value: 'AO', currency: 'AOA', phoneCode: '+244' },
    { label: 'Anguilla', value: 'AI', currency: 'XCD', phoneCode: '+1-264' },
    { label: 'Antarctica', value: 'AQ', currency: '', phoneCode: '' },
    { label: 'Antigua and Barbuda', value: 'AG', currency: 'XCD', phoneCode: '+1-268' },
    { label: 'Argentina', value: 'AR', currency: 'ARS', phoneCode: '+54' },
    { label: 'Armenia', value: 'AM', currency: 'AMD', phoneCode: '+374' },
    { label: 'Aruba', value: 'AW', currency: 'AWG', phoneCode: '+297' },
    { label: 'Australia', value: 'AU', currency: 'AUD', phoneCode: '+61' },
    { label: 'Austria', value: 'AT', currency: 'EUR', phoneCode: '+43' },
    { label: 'Azerbaijan', value: 'AZ', currency: 'AZN', phoneCode: '+994' },
    { label: 'Bahamas', value: 'BS', currency: 'BSD', phoneCode: '+1-242' },
    { label: 'Bahrain', value: 'BH', currency: 'BHD', phoneCode: '+973' },
    { label: 'Bangladesh', value: 'BD', currency: 'BDT', phoneCode: '+880' },
    { label: 'Barbados', value: 'BB', currency: 'BBD', phoneCode: '+1-246' },
    { label: 'Belarus', value: 'BY', currency: 'BYN', phoneCode: '+375' },
    { label: 'Belgium', value: 'BE', currency: 'EUR', phoneCode: '+32' },
    { label: 'Belize', value: 'BZ', currency: 'BZD', phoneCode: '+501' },
    { label: 'Benin', value: 'BJ', currency: 'XOF', phoneCode: '+229' },
    { label: 'Bermuda', value: 'BM', currency: 'BMD', phoneCode: '+1-441' },
    { label: 'Bhutan', value: 'BT', currency: 'BTN', phoneCode: '+975' },
    { label: 'Bolivia', value: 'BO', currency: 'BOB', phoneCode: '+591' },
    { label: 'Bosnia and Herzegovina', value: 'BA', currency: 'BAM', phoneCode: '+387' },
    { label: 'Botswana', value: 'BW', currency: 'BWP', phoneCode: '+267' },
    { label: 'Brazil', value: 'BR', currency: 'BRL', phoneCode: '+55' },
    { label: 'Brunei', value: 'BN', currency: 'BND', phoneCode: '+673' },
    { label: 'Bulgaria', value: 'BG', currency: 'BGN', phoneCode: '+359' },
    { label: 'Burkina Faso', value: 'BF', currency: 'XOF', phoneCode: '+226' },
    { label: 'Burundi', value: 'BI', currency: 'BIF', phoneCode: '+257' },
    { label: 'Cabo Verde', value: 'CV', currency: 'CVE', phoneCode: '+238' },
    { label: 'Cambodia', value: 'KH', currency: 'KHR', phoneCode: '+855' },
    { label: 'Cameroon', value: 'CM', currency: 'XAF', phoneCode: '+237' },
    { label: 'Canada', value: 'CA', currency: 'CAD', phoneCode: '+1' },
    { label: 'Central African Republic', value: 'CF', currency: 'XAF', phoneCode: '+236' },
    { label: 'Chad', value: 'TD', currency: 'XAF', phoneCode: '+235' },
    { label: 'Chile', value: 'CL', currency: 'CLP', phoneCode: '+56' },
    { label: 'China', value: 'CN', currency: 'CNY', phoneCode: '+86' },
    { label: 'Colombia', value: 'CO', currency: 'COP', phoneCode: '+57' },
    { label: 'Comoros', value: 'KM', currency: 'KMF', phoneCode: '+269' },
    { label: 'Congo', value: 'CG', currency: 'XAF', phoneCode: '+242' },
    { label: 'Costa Rica', value: 'CR', currency: 'CRC', phoneCode: '+506' },
    { label: 'Croatia', value: 'HR', currency: 'HRK', phoneCode: '+385' },
    { label: 'Cuba', value: 'CU', currency: 'CUP', phoneCode: '+53' },
    { label: 'Cyprus', value: 'CY', currency: 'EUR', phoneCode: '+357' },
    { label: 'Czechia', value: 'CZ', currency: 'CZK', phoneCode: '+420' },
    { label: 'Denmark', value: 'DK', currency: 'DKK', phoneCode: '+45' },
    { label: 'Djibouti', value: 'DJ', currency: 'DJF', phoneCode: '+253' },
    { label: 'Dominica', value: 'DM', currency: 'XCD', phoneCode: '+1-767' },
    { label: 'Dominican Republic', value: 'DO', currency: 'DOP', phoneCode: '+1-809' },
    { label: 'Ecuador', value: 'EC', currency: 'USD', phoneCode: '+593' },
    { label: 'Egypt', value: 'EG', currency: 'EGP', phoneCode: '+20' },
    { label: 'El Salvador', value: 'SV', currency: 'USD', phoneCode: '+503' },
    { label: 'Equatorial Guinea', value: 'GQ', currency: 'XAF', phoneCode: '+240' },
    { label: 'Eritrea', value: 'ER', currency: 'ERN', phoneCode: '+291' },
    { label: 'Estonia', value: 'EE', currency: 'EUR', phoneCode: '+372' },
    { label: 'Eswatini', value: 'SZ', currency: 'SZL', phoneCode: '+268' },
    { label: 'Ethiopia', value: 'ET', currency: 'ETB', phoneCode: '+251' },
    { label: 'Fiji', value: 'FJ', currency: 'FJD', phoneCode: '+679' },
    { label: 'Finland', value: 'FI', currency: 'EUR', phoneCode: '+358' },
    { label: 'France', value: 'FR', currency: 'EUR', phoneCode: '+33' },
    { label: 'Gabon', value: 'GA', currency: 'XAF', phoneCode: '+241' },
    { label: 'Gambia', value: 'GM', currency: 'GMD', phoneCode: '+220' },
    { label: 'Georgia', value: 'GE', currency: 'GEL', phoneCode: '+995' },
    { label: 'Germany', value: 'DE', currency: 'EUR', phoneCode: '+49' },
    { label: 'Ghana', value: 'GH', currency: 'GHS', phoneCode: '+233' },
    { label: 'Gibraltar', value: 'GI', currency: 'GIP', phoneCode: '+350' },
    { label: 'Greece', value: 'GR', currency: 'EUR', phoneCode: '+30' },
    { label: 'Greenland', value: 'GL', currency: 'DKK', phoneCode: '+299' },
    { label: 'Grenada', value: 'GD', currency: 'XCD', phoneCode: '+1-473' },
    { label: 'Guam', value: 'GU', currency: 'USD', phoneCode: '+1-671' },
    { label: 'Guatemala', value: 'GT', currency: 'GTQ', phoneCode: '+502' },
    { label: 'Guinea', value: 'GN', currency: 'GNF', phoneCode: '+224' },
    { label: 'Guinea-Bissau', value: 'GW', currency: 'XOF', phoneCode: '+245' },
    { label: 'Guyana', value: 'GY', currency: 'GYD', phoneCode: '+592' },
    { label: 'Haiti', value: 'HT', currency: 'HTG', phoneCode: '+509' },
    { label: 'Honduras', value: 'HN', currency: 'HNL', phoneCode: '+504' },
    { label: 'Hong Kong', value: 'HK', currency: 'HKD', phoneCode: '+852' },
    { label: 'Hungary', value: 'HU', currency: 'HUF', phoneCode: '+36' },
    { label: 'Iceland', value: 'IS', currency: 'ISK', phoneCode: '+354' },
    { label: 'India', value: 'IN', currency: 'INR', phoneCode: '+91' },
    { label: 'Indonesia', value: 'ID', currency: 'IDR', phoneCode: '+62' },
    { label: 'Iran', value: 'IR', currency: 'IRR', phoneCode: '+98' },
    { label: 'Iraq', value: 'IQ', currency: 'IQD', phoneCode: '+964' },
    { label: 'Ireland', value: 'IE', currency: 'EUR', phoneCode: '+353' },
    { label: 'Israel', value: 'IL', currency: 'ILS', phoneCode: '+972' },
    { label: 'Italy', value: 'IT', currency: 'EUR', phoneCode: '+39' },
    { label: 'Jamaica', value: 'JM', currency: 'JMD', phoneCode: '+1-876' },
    { label: 'Japan', value: 'JP', currency: 'JPY', phoneCode: '+81' },
    { label: 'Jordan', value: 'JO', currency: 'JOD', phoneCode: '+962' },
    { label: 'Kazakhstan', value: 'KZ', currency: 'KZT', phoneCode: '+7' },
    { label: 'Kenya', value: 'KE', currency: 'KES', phoneCode: '+254' },
    { label: 'Kuwait', value: 'KW', currency: 'KWD', phoneCode: '+965' },
    { label: 'Kyrgyzstan', value: 'KG', currency: 'KGS', phoneCode: '+996' },
    { label: 'Latvia', value: 'LV', currency: 'EUR', phoneCode: '+371' },
    { label: 'Lebanon', value: 'LB', currency: 'LBP', phoneCode: '+961' },
    { label: 'Libya', value: 'LY', currency: 'LYD', phoneCode: '+218' },
    { label: 'Liechtenstein', value: 'LI', currency: 'CHF', phoneCode: '+423' },
    { label: 'Lithuania', value: 'LT', currency: 'EUR', phoneCode: '+370' },
    { label: 'Luxembourg', value: 'LU', currency: 'EUR', phoneCode: '+352' },
    { label: 'Madagascar', value: 'MG', currency: 'MGA', phoneCode: '+261' },
    { label: 'Malaysia', value: 'MY', currency: 'MYR', phoneCode: '+60' },
    { label: 'Maldives', value: 'MV', currency: 'MVR', phoneCode: '+960' },
    { label: 'Mali', value: 'ML', currency: 'XOF', phoneCode: '+223' },
    { label: 'Malta', value: 'MT', currency: 'EUR', phoneCode: '+356' },
    { label: 'Mexico', value: 'MX', currency: 'MXN', phoneCode: '+52' },
    { label: 'Monaco', value: 'MC', currency: 'EUR', phoneCode: '+377' },
    { label: 'Mongolia', value: 'MN', currency: 'MNT', phoneCode: '+976' },
    { label: 'Montenegro', value: 'ME', currency: 'EUR', phoneCode: '+382' },
    { label: 'Montserrat', value: 'MS', currency: 'XCD', phoneCode: '+1-664' },
    { label: 'Morocco', value: 'MA', currency: 'MAD', phoneCode: '+212' },
    { label: 'Mozambique', value: 'MZ', currency: 'MZN', phoneCode: '+258' },
    { label: 'Myanmar', value: 'MM', currency: 'MMK', phoneCode: '+95' },
    { label: 'Namibia', value: 'NA', currency: 'NAD', phoneCode: '+264' },
    { label: "Nauru", value: "NR", currency: 'AUD', phoneCode: '+674' },
    { label: "Nepal", value: "NP", currency: 'NPR', phoneCode: '+977' },
    { label: "Netherlands", value: "NL", currency: 'EUR', phoneCode: '+31' },
    { label: "New Caledonia", value: "NC", currency: 'XPF', phoneCode: '+687' },
    { label: "New Zealand", value: "NZ", currency: 'NZD', phoneCode: '+64' },
    { label: "Nicaragua", value: "NI", currency: 'NIO', phoneCode: '+505' },
    { label: "Niger", value: "NE", currency: 'XOF', phoneCode: '+227' },
    { label: "Nigeria", value: "NG", currency: 'NGN', phoneCode: '+234' },
    { label: "Niue", value: "NU", currency: 'NZD', phoneCode: '+683' },
    { label: "Norfolk Island", value: "NF", currency: 'AUD', phoneCode: '+672' },
    { label: "Northern Mariana Islands", value: "MP", currency: 'USD', phoneCode: '+1-670' },
    { label: "Norway", value: "NO", currency: 'NOK', phoneCode: '+47' },
    { label: "Oman", value: "OM", currency: 'OMR', phoneCode: '+968' },
    { label: "Pakistan", value: "PK", currency: 'PKR', phoneCode: '+92' },
    { label: "Palau", value: "PW", currency: 'USD', phoneCode: '+680' },
    { label: "Palestine, State of", value: "PS", currency: '', phoneCode: '+970' },
    { label: "Panama", value: "PA", currency: 'PAB', phoneCode: '+507' },
    { label: "Papua New Guinea", value: "PG", currency: 'PGK', phoneCode: '+675' },
    { label: "Paraguay", value: "PY", currency: 'PYG', phoneCode: '+595' },
    { label: "Peru", value: "PE", currency: 'PEN', phoneCode: '+51' },
    { label: "Philippines", value: "PH", currency: 'PHP', phoneCode: '+63' },
    { label: "Pitcairn", value: "PN", currency: 'NZD', phoneCode: '+64' },
    { label: "Poland", value: "PL", currency: 'PLN', phoneCode: '+48' },
    { label: "Portugal", value: "PT", currency: 'EUR', phoneCode: '+351' },
    { label: "Puerto Rico", value: "PR", currency: 'USD', phoneCode: '+1-787' },
    { label: "Qatar", value: "QA", currency: 'QAR', phoneCode: '+974' },
    { label: "Republic of North Macedonia", value: "MK", currency: 'MKD', phoneCode: '+389' },
    { label: "Romania", value: "RO", currency: 'RON', phoneCode: '+40' },
    { label: "Russian Federation", value: "RU", currency: 'RUB', phoneCode: '+7' },
    { label: "Rwanda", value: "RW", currency: 'RWF', phoneCode: '+250' },
    { label: "Réunion", value: "RE", currency: 'EUR', phoneCode: '+262' },
    { label: "Saint Barthélemy", value: "BL", currency: 'EUR', phoneCode: '+590' },
    { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SH", currency: 'SHP', phoneCode: '+290' },
    { label: "Saint Kitts and Nevis", value: "KN", currency: 'XCD', phoneCode: '+1-869' },
    { label: "Saint Lucia", value: "LC", currency: 'XCD', phoneCode: '+1-758'},
    { label: "Saint Martin (French part)", value: "MF", phoneCode: "+590", currency: "EUR" },
    { label: "Saint Pierre and Miquelon", value: "PM", phoneCode: "+508", currency: "EUR" },
    { label: "Saint Vincent and the Grenadines", value: "VC", phoneCode: "+1-784", currency: "XCD" },
    { label: "Samoa", value: "WS", phoneCode: "+685", currency: "WST" },
    { label: "San Marino", value: "SM", phoneCode: "+378", currency: "EUR" },
    { label: "Sao Tome and Principe", value: "ST", phoneCode: "+239", currency: "STN" },
    { label: "Saudi Arabia", value: "SA", phoneCode: "+966", currency: "SAR" },
    { label: "Senegal", value: "SN", phoneCode: "+221", currency: "XOF" },
    { label: "Serbia", value: "RS", phoneCode: "+381", currency: "RSD" },
    { label: "Seychelles", value: "SC", phoneCode: "+248", currency: "SCR" },
    { label: "Sierra Leone", value: "SL", phoneCode: "+232", currency: "SLL" },
    { label: "Singapore", value: "SG", phoneCode: "+65", currency: "SGD" },
    { label: "Sint Maarten (Dutch part)", value: "SX", phoneCode: "+1-721", currency: "ANG" },
    { label: "Slovakia", value: "SK", phoneCode: "+421", currency: "EUR" },
    { label: "Slovenia", value: "SI", phoneCode: "+386", currency: "EUR" },
    { label: "Solomon Islands", value: "SB", phoneCode: "+677", currency: "SBD" },
    { label: "Somalia", value: "SO", phoneCode: "+252", currency: "SOS" },
    { label: "South Africa", value: "ZA", phoneCode: "+27", currency: "ZAR" },
    { label: "South Georgia and the South Sandwich Islands", value: "GS", phoneCode: "+500", currency: "GBP" },
    { label: "South Sudan", value: "SS", phoneCode: "+211", currency: "SSP" },
    { label: "Spain", value: "ES", phoneCode: "+34", currency: "EUR" },
    { label: "Sri Lanka", value: "LK", phoneCode: "+94", currency: "LKR" },
    { label: "Sudan", value: "SD", phoneCode: "+249", currency: "SDG" },
    { label: "Suriname", value: "SR", phoneCode: "+597", currency: "SRD" },
    { label: "Svalbard and Jan Mayen", value: "SJ", phoneCode: "+47", currency: "NOK" },
    { label: "Sweden", value: "SE", phoneCode: "+46", currency: "SEK" },
    { label: "Switzerland", value: "CH", phoneCode: "+41", currency: "CHF" },
    { label: "Syrian Arab Republic", value: "SY", phoneCode: "+963", currency: "SYP" },
    { label: "Taiwan", value: "TW", phoneCode: "+886", currency: "TWD" },
    { label: "Tajikistan", value: "TJ", phoneCode: "+992", currency: "TJS" },
    { label: "Tanzania", value: "TZ", phoneCode: "+255", currency: "TZS" },
    { label: "Thailand", value: "TH", phoneCode: "+66", currency: "THB" },
    { label: "Timor-Leste", value: "TL", phoneCode: "+670", currency: "USD" },
    { label: "Togo", value: "TG", phoneCode: "+228", currency: "XOF" },
    { label: "Tokelau", value: "TK", phoneCode: "+690", currency: "NZD" },
    { label: "Tonga", value: "TO", phoneCode: "+676", currency: "TOP" },
    { label: "Trinidad and Tobago", value: "TT", phoneCode: "+1-868", currency: "TTD" },
    { label: "Tunisia", value: "TN", phoneCode: "+216", currency: "TND" },
    { label: "Turkey", value: "TR", phoneCode: "+90", currency: "TRY" },
    { label: "Turkmenistan", value: "TM", phoneCode: "+993", currency: "TMT" },
    { label: "Turks and Caicos Islands", value: "TC", phoneCode: "+1-649", currency: "USD" },
    { label: "Tuvalu", value: "TV", phoneCode: "+688", currency: "AUD" },
    { label: "Uganda", value: "UG", phoneCode: "+256", currency: "UGX" },
    { label: "Ukraine", value: "UA", phoneCode: "+380", currency: "UAH" },
    { label: "United Arab Emirates", value: "AE", phoneCode: "+971", currency: "AED" },
    { label: "United Kingdom of Great Britain and Northern Ireland", value: "GB", phoneCode: "+44", currency: "GBP" },
    { label: "United States Minor Outlying Islands", value: "UM", phoneCode: "+1", currency: "USD" },
    { label: "United States of America", value: "US", phoneCode: "+1", currency: "USD" },
    { label: "Uruguay", value: "UY", phoneCode: "+598", currency: "UYU" },
    { label: "Uzbekistan", value: "UZ", phoneCode: "+998", currency: "UZS" },
    { label: "Vanuatu", value: "VU", phoneCode: "+678", currency: "VUV" },
    { label: "Venezuela (Bolivarian Republic of)", value: "VE", phoneCode: "+58", currency: "VES" },
    { label: "Viet Nam", value: "VN", phoneCode: "+84", currency: "VND" },
    { label: "Virgin Islands (British)", value: "VG", phoneCode: "+1-284", currency: "USD" },
    { label: "Virgin Islands (U.S.)", value: "VI", phoneCode: "+1-340", currency: "USD" },
    { label: "Wallis and Futuna", value: "WF", phoneCode: "+681", currency: "XPF" },
    { label: "Western Sahara", value: "EH", phoneCode: "+212", currency: "MAD" },
    { label: "Yemen", value: "YE", phoneCode: "+967", currency: "YER" },
    { label: "Zambia", value: "ZM", phoneCode: "+260", currency: "ZMW" },
    { label: "Zimbabwe", value: "ZW", phoneCode: "+263", currency: "ZWL" },
    { label: "Åland Islands", value: "AX", phoneCode: "+358", currency: "EUR" }
  ];