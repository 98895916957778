import emptyimage from '.././k2.png';
import React, { useEffect, useRef, useState } from 'react'
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable'
import { EmptyState } from '../../../../libs/EmptyState'
import { TableActions } from '../../../../libs/Table/TableAction';
import { Modal } from '../../../../libs/Modal/Modal';
import { showToast } from '../../../../libs/Toast/ToastCallback';
import { handleError } from '../../../../libs/fToast/ToastCallback';
import { post } from '../../../../../Model/Network/Config/Axios';
import { FloatInput } from '../../../../libs/FloatLabelInput';
import ButtonOutline from '../../../../libs/ButtonOutline';
import { DatePicker1 } from '../../../../libs/DatePicker/DateRangePicker1';
import moment from 'moment';
import Search from '../../../../libs/Search/Search';

function CancellationRequest(props) {
    const [loading,setLoading] = useState(false);
    const [requests, setRequests] = useState([]);
    let REMARKS=useRef()
    const [modal,setModal] = useState(false);
    const [dateFilter, setDateFilter] = useState(false);
    const [search, setSearch] = useState();
    const [range, setRange] = useState(false);

    let Item = (item, index) => {
        let actions = [
          {
            name : "Accept",
            click : ()=>{setModal({item,type:"Confirm"})}
          },
          {
            name : "Decline",
            click : ()=>{setModal({item,type:"Decline"})}
          }
        ];
        return (
              <div className="border-bottom" style={{
                padding: 10
              }}>
                <div className="linear-layout horizontal" style={{ flex: 1 }}>
                  <div style={{
                    flex: 2
                  }}>
                    <p style={{ fontWeight: 500, fontSize: 14,alignItems: 'flex-end',maxWidth:'250px' }}>{item.requestId}</p>
                    <p style={{ fontWeight: 500, fontSize: 14,maxWidth:'250px' }}>Reason : {item.reason}</p>
                    {(item && item.ownerName)?<p style={{ fontWeight: 500, fontSize: 14,maxWidth:'250px' }}>Employee  : {item.ownerName} {(item && item.ownerCode)?`(${item.ownerCode})`:""}</p>:null}
                  </div>
                  <div style={{
                    flex: 2
                  }}>
                    <p style={{ fontWeight: 500, fontSize: 14,alignItems: 'flex-end',maxWidth:'200px' }}>{(item && item.tripId)?item.tripId:"-"}</p>
                    {(item.withdrawRemark || item.declineRemark) ? <p style={{ fontWeight: 500, fontSize: 14,maxWidth:'250px' }}>Remarks : {item.withdrawRemark || item.declineRemark}</p> : null}
                  </div>
                  <div style={{
                    flex: 1.5
                  }}>
                    <p onClick={()=>{
                      if(item && item.service){
                        if(item.service.toLowerCase()==="hotel"){
                          window.open(`/app/hotel/tickets/${item.transId}`,"_blank")
                        }
                        else if(item.service.toLowerCase()==="bus" || item.service.toLowerCase()==="cab"){
                          window.open(`/app/bus/tickets/${item.transId}`,"_blank")
                        }
                        else if(item.service.toLowerCase()==="train"){
                          window.open(`/app/train/tickets/${item.transId}`,"_blank")
                        }
                        else if(item.service.toLowerCase()==="flight"){
                          window.open(`/app/flights/tickets/${item.transId}`,"_blank")
                        }
                      }
                      else{
                        if(window.location.pathname.includes("hotel")){
                          window.open(`/app/hotel/tickets/${item.transId}`,"_blank")
                        }
                        else if(window.location.pathname.includes("bus") || window.location.pathname.includes("cab")){
                          window.open(`/app/bus/tickets/${item.transId}`,"_blank")
                        }
                        else if(window.location.pathname.includes("train")){
                          window.open(`/app/train/tickets/${item.transId}`,"_blank")
                        }
                        else if(window.location.pathname.includes("flight")){
                          window.open(`/app/flights/tickets/${item.transId}`,"_blank")
                        }
                      }
                    }} style={{ fontWeight: 'bold', fontSize: 14,alignItems: 'flex-end',maxWidth:'200px',textDecoration:'underline',cursor:'pointer'}}>{item.transId}</p>
                  </div>
                  <div style={{
                    flex: 1,
                    ...(props && props.past)?{display:'flex',justifyContent:'flex-end'}:{}
                  }}>
                    <p style={{ fontWeight: 500, fontSize: 14,maxWidth:'250px' }}>{item.status}</p>
                  </div>
                  {(props && !props.past)?<div style={{
                    flex: 1,
                    display:'flex',
                    justifyContent:'flex-end'
                  }} className="right-actions">
                   
                    <TableActions items={actions}/>
                  </div>:null}
                </div>
              </div>
            )
    }

    useEffect(()=>{

      if(range || search){

        const updatedRequests = props.requests.filter(el => {
          if(!range)  return true;
          if(el.createdAt){
            let date = new moment(el.createdAt);
            if(date >= moment(range.startDate).valueOf()  && date<= moment(range.endDate).valueOf()) return true;
            else return false;
          }else return false;
        }).filter(el => {
          if(!search)  return true;
          const dummy={...el};
          delete dummy["createdAt"];
          delete dummy["id"];
          delete dummy["transactionId"];
          delete dummy["updatedAt"];
          const stringified = JSON.stringify(dummy);
          if(stringified.toLowerCase().includes(search.toLowerCase())) return true;

          return false;
        })

        setRequests(updatedRequests);
      }else{ 
        setRequests(props.requests);
      }
    },[range, search]);

    useEffect(()=>{
      setRequests(props.requests);
    },[props.requests]);

    if(props.requests.length===0){
      return <EmptyState title="No Cancellation Request Found" desc="No Requests Found" image={emptyimage} />
    }

    return (
      <div>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}} >
            <Search styles={{width:'50%'}} placeholder="Search on the basis of Transaction Id, Request Id, Status or Remarks" onChange={(e)=>setSearch(e.target.value)} query={search} />   
            <div> 
              {
                range ? 
                <span className="btn btn-portlet-invert"
                      style={{ marginRight: 5, display: 'inline', width: 230, color: '#777', borderColor: "var(--outline-color)", height: 35, padding: '4px 10px' }}>
                      {moment(range.startDate).format('DD-MMM-YYYY')} - {moment(range.endDate).format('DD-MMM-YYYY')}
                      <span onClick={() => {
                        setRange(false);
                      }} style={{
                        marginRight: 5,
                        marginLeft: 10,
                        marginTop: 4,
                        fontSize: 13,
                        cursor: 'pointer'
                      }}>×</span>
                    </span>
                  :
                <ButtonOutline title="Add Date Filter" onClick={() => {
                  setDateFilter(true);
                }} />
              }
            </div>
        </div>

 				<NormalTable headerStyle={{ border: 'none', marginTop:'3rem' }} headers={[
					{
						key: 'Request Id',
						name: 'Request Id',
						weight: 2
					},
          {
						key: 'Trip Id',
						name: 'Trip Id',
						weight: 2
					},
					{
						key: 'Transaction Id',
						name: 'Transaction Id',
						weight: 1.5
					},
          {
						key: 'Status',
						name: 'Status',
						weight: 1,
            ...(props && props.past)?{style:{textAlign:'right',justifyContent:'flex-end'}}:{}
					},
          ...(props && !props.past)?[{
						key: 'Action',
						name: 'Action',
						weight: 1,
            style:{textAlign:'right',justifyContent:'flex-end'}
					}]:[]
				]}
					mapItem={Item}
					pagination={true}
					data={requests}
				/>
        {(modal && modal.type)?<Modal
            buttonName={loading?"Loading...":"Confirm"}
            des=""
            title={`${modal.type} Cancellation Request`}
            close={() => {
                setModal(false)
            }}
            style={{
                save: {
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 17,
                    padding: "12px 20px",
                    background: "#7558F6",
                    border: "1px solid #7558F6"
                },
            }} show={true} onSave={()=>{
              if(!loading){
                  setLoading(true);
                  if(modal.type==="Confirm"){
                    post(`/requests/cancellationRequest/${modal.item.id}/acknowledge`,{},(e,r)=>{
                      if(r){
                          showToast({
                              type: 'success',
                              message: "Cancellation request successfully acknowledged"
                          })
                          setLoading(false)
                          setModal(false)
                          props.init();
                      }
                      else if(e){
                          setLoading(false)
                          handleError(e)
                          return;
                      }
                      })
                  }
                  else{
                    let remarks=REMARKS.value
                    post(`/requests/cancellationRequest/${modal.item.id}/decline`,{
                      "declineRemark" : remarks
                    },(e,r)=>{
                      if(r){
                          showToast({
                              type: 'success',
                              message: "Cancellation request declined successfully"
                          })
                          setLoading(false)
                          setModal(false)
                          props.init();
                      }
                      else if(e){
                          setLoading(false)
                          handleError(e)
                          return;
                      }
                      })
                  }
                }
            }}>
            <div>
                <p>{modal.type} Cancellation Request {modal.item.requestId} For {modal.item.transId}</p>
                {(modal.type==="Decline")?<FloatInput title="Enter Remarks" type="text" handleRef={({ref})=>{
                  REMARKS = ref;
                }}/>:null}
            </div>
        </Modal>:null}
          {
            dateFilter ?
              <DatePicker1
                apply={(range) => {
                  setRange(range);
                  setDateFilter(false);
                }}
                handle={() => { }}
                onClose={() => {
                  setDateFilter(false);
                }}
              /> : null
          }
			</div>
    )
}

export default CancellationRequest