import React, { useState } from 'react'
import { NormalTable } from '../../../Tables/NormalTable/NormalTable';
import VisaRequestMap from '../VisaRequestMap';
import Filter from '../../Common/Filter/index';

function OpenRequests({data}) {

    const [open,setOpen] = useState(false);
    const [requestId,setRequestId] = useState(false);
    const [filters, setFilters] = useState([]);
    const [type, setType] = useState({
        label: "By Name",
        value: "NAME",
    });

    const addFilter = (value) => {
        if (value) {
            let types = type.value;
            let filter = filters.filter(item => {
                return !(item.type === types && item.value === value);
            });
            filter.push({
                type: types, value
            });
            setFilters(filter);
        }
    }
    const removeFilter = item => {
        let filter = filters;
        filter = filter.filter(mItem => {
            return !(item.type === mItem.type && item.value === mItem.value);
        });
        setFilters(filter);
    };

    const mapItem=(item,index)=>{
        return <VisaRequestMap  
        isOpenRequest={true}
        key={index} item={item} open={open}
        requestId={requestId}
        onCloseRequestId={() => {
            setRequestId(false);
        }}
        onSetRequestId={(requestId) => {
            setRequestId(requestId);
        }}

        onSetOpen={(open) => {
            setOpen(open);
        }}
        onCloseOpen={() => {
            setOpen(false);
        }}
        />
    }

  return (
    <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Filter  
                data={data} 
                filters={filters} 
                type={type} 
                addFilter={addFilter} 
                removeFilter={removeFilter}
                onTypeChange={type => setType(type)}  
                types={[
                    { label: "By Name", value: "NAME" },
                    { label: 'By Employee Code', value: 'EMPCODE' },
                ]}
            />
        </div>
        <NormalTable
            headerStyle={{
                border: 'none'
            }}
            headers={[]}
            data={data}
            mapItem={mapItem}
            pagination={true}
        />
    </div>
  )
}

export default OpenRequests