import React from 'react'
import { SearchableSelect } from '../../libs/Select/SearchableSelec2';
import { get } from '../../../Model/Network/Config/Axios';

class TripSearchable extends React.Component {

	state = {
		trip : undefined,
		items :[],
        filtered:[]
	}

    componentDidMount(){
        get(`trip/all`,(e,r)=>{
            if(r && r.trips){
                let items=[];
                items=r.trips.filter(ele=>ele.tripId).map(ele=>({label:ele.tripId,value:`${ele.id}_${ele.tripId}`,employee:ele.employee}))
                this.setState({
                    ...this.state,
                    items,
                    filtered:items
                });
            }
        })
    }

	onChange = q => {
		if(q.length){
            const filteredItems=this.state.items.filter(ele=>ele.value.toLowerCase().includes(q.toLowerCase()));
            this.setState({...this.state,filtered:filteredItems})
        }else{
            this.setState({...this.state,filtered:this.state.items});
        }
	}

	render() {

        return (
			<div>
				<SearchableSelect
					items={this.state.filtered}
					onChange={this.onChange}
					label={"Trip"}
					placeholder={"Select Trip"} 
                    select={(val)=>{
                        if(this.props && this.props.addEmployeeOfTrip){
                            this.props.addEmployeeOfTrip(val)
                        }
                        this.props.onSelect(val.value);
                        this.setState({...this.state,trip:val});
                    }} 
                    value={this.state.trip}
                />
			</div>
		)
	}

}

export default TripSearchable;