import React, { useRef, useState, useEffect } from 'react'
import { SidePane } from '../../../libs/fSidePane/RightPane'
import axios from '../../../../Model/Network/Config/Axios';
import ButtonOutline from '../../../libs/ButtonOutline';
import { showToast } from '../../../libs/Toast/ToastCallback';
import papa from 'papaparse';
import { DownloadCsv } from '../../../../Utils/Downloader';
import moment from 'moment';
import { StateButton } from '../../Common/StateButton';
import { api } from '../../../../Model/Network/Api/config';

const Item = ({ title, desc }) => {
    return (
        <div style={{
            width: '33%',
            padding: 12
        }}>
            <div style={{
                border: '1px solid var(--border-color)',
                padding: 18,
            }}>
                <p style={{ color: 'var(--text-color)', fontSize: 13 }}>{title}</p>
                <p style={{
                    fontSize: 20, marginTop: 10
                }}>{desc}</p>
            </div>
        </div>
    )
}

const BulkUpload = ({ onClose }) => {
    const FILE = useRef();
    const [state, setState] = useState({ url: undefined, loading: false })
    const [buttonloading, setButtonLoading] = useState(false);
    const [completed, setCompleted] = useState(null);
    const [length, setLength] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [errorCSV, setErrorCSV] = useState([]);

    const MyFilter = (origional) => {
        let temp = [];
        origional.forEach((el) => {
            let err = { ...el };
            let errorMessage = "";
            if (!el.origin) errorMessage = "Origin is required";
            if (!el.destination) errorMessage = "Destination is required";
            if (!el['departure(DD-MM-YYYY-HH-mm)']) errorMessage = "Departure date is required";
            if (!el['arrival(DD-MM-YYYY-HH-mm)']) errorMessage = "Arrival date is required";
            if (!el.bus_name) errorMessage = "Bus name is required";
            if (!el.bus_operator) errorMessage = "Bus operator is required";
            if (!el.pnr) errorMessage = "PNR is required";
            if (!el.guest || !el.guest.length) errorMessage = "Guest is required";
            if (!el.amount) errorMessage = "Amount is required";

            if (!errorMessage) {
                temp.push(err);
            } else {
                err.error = errorMessage;
                setErrorCSV(prev => [...prev, err]);
            }
        })
        return temp;
    }

    const handleErrorCSV = (error, data) => {
        let err = { ...data };
        err.error = error.data ? error.data.message : "API Error";
        setErrorCSV(prev => [...prev, err]);
    }

    const downloadErrorCSV = () => {
        let errorList = [];
        let base = Object.keys(errorCSV[0]);
        errorList.push(base);
        errorCSV.forEach((el) => {
            errorList.push(Object.values(el));
        })
        DownloadCsv("Error.csv", errorList);
    }


    let upload = () => {
        let file = state.url;
        if (!file) {
            showToast({
                type: 'error',
                message: 'Please select the valid file'
            })
            return;
        }

        papa.parse(file, {
            download: true,
            delimiter: ",",	// auto-detect
            newline: "",
            header: true,
            transformHeader: undefined,
            dynamicTyping: false,
            preview: 0,
            encoding: "",
            worker: false,
            comments: false,
            complete: (result, file) => {
                let final_sheet = []
                final_sheet = MyFilter(result.data);
                if(final_sheet.length === 0) setCompleted(0)
                setButtonLoading(true);
                bulkUpload(final_sheet)
            },
            delimitersToGuess: [',', '\t', '|']
        })
    }

    let bulkUpload = (all_data) => {
        startUpload(all_data);
        setLength(all_data.length);
    }

    let startUpload = async (data) => {
        let count = 0;
        for (const item in data) {
            const res = await init(data[item]);
            count = res + count;
        }
        setCompleted(count)
        setButtonLoading(false);
        setUploading(true);
        if(count === data.length){
            showToast({
                type: 'success',
                message: "Bulk Transaction Update Successfully"
            })
        }else{
            showToast({
                type: 'error',
                message: "Bulk Transaction Update failed"
            })
        }

    }

    useEffect(() => {
        if (completed!==null && errorCSV.length) {
            downloadErrorCSV();
        }
    }, [completed, errorCSV, length])

    let init = async (val) => {
        let temp = {
            amount: Number(val.amount),
            arrival: {city:val.destination,time:moment(val['departure(DD-MM-YYYY-HH-mm)'],"DD-MM-YYYY-HH-mm").valueOf()},
            departure: {city:val.origin,time:moment(val['arrival(DD-MM-YYYY-HH-mm)'],"DD-MM-YYYY-HH-mm").valueOf()},
            bus: {name:val.bus_name,operator:val.bus_operator},
            guests: val.guest.split(",").map(el => el.trim()),
            invoice: "",
            pnr: val.pnr
        };
        try {
            let url = api;
            if(url.endsWith('/')) url = url.slice(0,-1);
            await axios.post(`${url}/bus/import`,temp)
            return 1;
        } catch (error) {
            handleErrorCSV(error,val);
            return 0
        }
    }

    const handleFileSelection = (val) => {
        let file = FILE.current.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            setState(prev => ({ ...prev, loading: true }))
            axios({
                method: 'post',
                url: 'https://file.eka.io/file',
                data: formData,
                headers: {
                    clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
                    clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
                }
            }).then(res => {
                setState(prev => ({ ...prev, url: res.data.url, loading: false }))
            }).catch(err => {
                alert("Unable to upload file")
                setState(prev => ({ ...prev, loading: false }))
            })
        } else {
            setState(prev => ({ ...prev, url: undefined, loading: false }))
        }
    }
    return (
        <SidePane button={false} onClose={onClose}>

            <>
                <div
                    style={{ color: "var(--text-color)", fontWeight: 700, fontSize: "28px", lineHeight: "1.5", marginBottom: "25px" }}
                >
                    Bulk Upload Booking
                </div>
                <hr />
                <section>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                        <label htmlFor='importBooking'>Select File</label>
                        <input id="importBooking" type="file" onChange={handleFileSelection} ref={FILE} style={{
                            height: '100%',
                            width: '100%',
                            padding: '10px 20px',
                            border: '1px solid var(--border-color)'
                        }} />
                    </div>
                    {buttonloading? <StateButton/> :
                    <ButtonOutline style={{ marginTop: 10 }} title='Upload' disabled={!state.url} onClick={upload} />
                    }

                    {
                        uploading ?
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                marginTop: 30
                            }}>
                                <Item title="Total Request" desc={length} />
                                <Item title="Success Request" desc={completed} />
                            </div>
                            :
                            <div style={{
                                marginTop: 30,
                                fontSize: 13
                            }}>
                                <span onClick={() => {
                                    DownloadCsv("BusBooking.csv",[["origin","destination","departure(DD-MM-YYYY-HH-mm)","arrival(DD-MM-YYYY-HH-mm)","bus_name","bus_operator","pnr","amount","guest"]])
                                }} style={{ color: 'var(--primary-color)', cursor: 'pointer', }}>Download Csv</span> for format
                            </div>
                    }
                </section>
            </>

        </SidePane>
    )
}

export default BulkUpload