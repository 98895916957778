class Presentor {

	constructor(Model) {
		this.Model = Model;
	}

	setView(View) {
		this.View = View;
	}

	destroy = () => {
		this.Model.destroy()
	}


	init = () => {
		this.Model.fetch((e, r) => {
			if (r) {
				this.View.load(r)
			}
		})
	}

	delete = (id, remarks) => {
		this.Model.delete(this.View.getService(), id, { remarks }, () => {
			this.init()
		})
	}

}


export default Presentor