import React, { useEffect, useRef, useState } from 'react'
import { SidePane } from '../../../libs/fSidePane/RightPane'
import axios from '../../../../Model/Network/Config/Axios';
import ButtonOutline from '../../../libs/ButtonOutline';
import { showToast } from '../../../libs/Toast/ToastCallback';
import papa from 'papaparse';
import { DownloadCsv } from '../../../../Utils/Downloader';
import moment from 'moment';
import { StateButton } from '../../Common/StateButton';
import { api } from '../../../../Model/Network/Api/config';

const Item = ({ title, desc }) => {
    return (
        <div style={{
            width: '33%',
            padding: 12
        }}>
            <div style={{
                border: '1px solid var(--border-color)',
                padding: 18,
            }}>
                <p style={{ color: 'var(--text-color)', fontSize: 13 }}>{title}</p>
                <p style={{
                    fontSize: 20, marginTop: 10
                }}>{desc}</p>
            </div>
        </div>
    )
}

const BulkUpload = ({ onClose }) => {
    const FILE = useRef();
    const [state, setState] = useState({ url: undefined, loading: false })
    const [buttonloading, setButtonLoading] = useState(false);
    const [completed, setCompleted] = useState(null);
    const [length, setLength] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [errorCSV, setErrorCSV] = useState([]);

    const MyFilter = (origional) => {
        let temp = [];
        origional.forEach((el) => {
            let err = {...el};
            let errorMessage = "";
            if(!el.origin_city) errorMessage += "Origin City is missing. ";
            if(!el.destination_city) errorMessage += "Destination City is missing. ";
            if(!el['departure_time(DD-MM-YYYY)']) errorMessage += "Departure Time is missing. ";
            if(!el['arrival_time(DD-MM-YYYY)']) errorMessage += "Arrival Time is missing. ";
            if(!el.airline_name) errorMessage += "Airline Name is missing. ";
            if(!el.pnr_number) errorMessage += "PNR Number is missing. ";
            if(!el.base_amount) errorMessage += "Base Amount is missing. ";
            if(!el.total_amount) errorMessage += "Total Amount is missing. ";
            if(!el.flight_number) errorMessage += "Flight Number is missing. ";
            if(!el.flight_code) errorMessage += "Flight Code is missing. ";
            if(!el.guests || !el.guests.length) errorMessage += "Guests is missing. ";
            if(!errorMessage){
                temp.push(err);
            }else{
                err.error = errorMessage;
                setErrorCSV(prev => [...prev, err]);
            }
        })
        return temp;
    }

    const handleErrorCSV =  (error, data) => {
        let err = {...data};
        err.error = error.data?error.data.message:"API Error";
        setErrorCSV(prev=>[...prev,err]);
    }

    const downloadErrorCSV = () => {
        let errorList = [];
        let base = Object.keys(errorCSV[0]);
        errorList.push(base);
        errorCSV.forEach((el) => {
            errorList.push(Object.values(el));
        })
        setErrorCSV([])
        DownloadCsv("Error.csv",errorList);
    }

    let upload = () => {
        let file = state.url;
        if (!file) {
            showToast({
                type: 'error',
                message: 'Please select the valid file'
            })
            return;
        }

        papa.parse(file, {
            download: true,
            delimiter: ",",	// auto-detect
            newline: "",
            header: true,
            transformHeader: undefined,
            dynamicTyping: false,
            preview: 0,
            encoding: "",
            worker: false,
            comments: false,
            complete: (result, file) => {
                let final_sheet = []
                final_sheet = MyFilter(result.data);
                if(final_sheet.length === 0) setCompleted(0)
                setButtonLoading(true);
                bulkUpload(final_sheet)
            },
            delimitersToGuess: [',', '\t', '|']
        })
    }

    let bulkUpload = (all_data) => {
        startUpload(all_data);
        setLength(all_data.length);
    }

    let startUpload = async (data) => {
        let count = 0;
        for (const item in data) {
            const res = await init(data[item]);
            count = res + count;
        }
        setCompleted(count)
        setButtonLoading(false);
        setUploading(true);
        if(count === data.length){
            showToast({
                type: 'success',
                message: "Bulk Transaction Update Successfully"
            })
        }else{
            showToast({
                type: 'error',
                message: "Bulk Transaction Update failed"
            })
        }

    }

    useEffect(()=>{
        if(completed!==null && errorCSV.length){
            downloadErrorCSV();
        }
    },[completed,errorCSV,length])

    let init = async (ele) => {
        let temp = {
            guests: ele.guests.split(',').map(emp => emp.trim()),
            amount: +ele.total_amount,
            pnr: ele.pnr_number,
            flight: {
                code: ele.flight_code,
                number: ele.flight_number,
                airline: ele.airline_name,
            },
            departure: {
                airport: ele.origin_city,
                time: moment(ele['departure_time(DD-MM-YYYY-HH-mm)'],"DD-MM-YYYY-HH-mm").valueOf()
            },
            arrival: {
                airport: ele.destination_city,
                time: moment(ele['arrival_time(DD-MM-YYYY-HH-mm)'],"DD-MM-YYYY-HH-mm").valueOf()
            },
            invoice: "",
            base_amount: +ele.base_amount,
            igst: ele.igst?+ele.igst:0,
            sgst: ele.sgst?+ele.sgst:0,
            cgst: ele.cgst?+ele.cgst:0,
            total_tax: +((ele.igst?Number(ele.igst):0)+(ele.sgst?Number(ele.sgst):0)+(ele.cgst?Number(ele.cgst):0)),
            other_taxes: ele.other_taxes?parseFloat(ele.other_taxes):0
        };
        if (ele.invoice_number) temp.invoice_no = ele.invoice_number
        if (ele.vendor_code) temp.vendor_code = ele.vendor_code
        if (ele.sellergstin) temp.seller_gstin = ele.sellergstin
        if (ele['invoice_date(DD-MM-YYYY)']) temp.invoice_date = moment(ele['invoice_date(DD-MM-YYYY)'],"DD-MM-YYYY").valueOf()
        try {
            let url = api;
            if(url.endsWith('/')) url = url.slice(0,-1);
            await axios.post(`${url}/flight/import`,temp)
            return 1;
        } catch (error) {
            handleErrorCSV(error,ele);
            return 0
        }
    }

    const handleFileSelection = (val) => {
        let file = FILE.current.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            setState(prev => ({ ...prev, loading: true }))
            axios({
                method: 'post',
                url: 'https://file.eka.io/file',
                data: formData,
                headers: {
                    clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
                    clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
                }
            }).then(res => {
                setState(prev => ({ ...prev, url: res.data.url, loading: false }))
            }).catch(err => {
                alert("Unable to upload file")
                setState(prev => ({ ...prev, loading: false }))
            })
        } else {
            setState(prev => ({ ...prev, url: undefined, loading: false }))
        }
    }
    return (
        <SidePane button={false} onClose={onClose}>

            <>
                <div
                    style={{ color: "var(--text-color)", fontWeight: 700, fontSize: "28px", lineHeight: "1.5", marginBottom: "25px" }}
                >
                    Bulk Upload Booking
                </div>
                <hr />
                <section>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                        <label htmlFor='importBooking'>Select File</label>
                        <input id="importBooking" type="file" onChange={handleFileSelection} ref={FILE} style={{
                            height: '100%',
                            width: '100%',
                            padding: '10px 20px',
                            border: '1px solid var(--border-color)'
                        }} />
                    </div>
                    {buttonloading? <StateButton/> :
                    <ButtonOutline style={{ marginTop: 10 }} title='Upload' disabled={!state.url} onClick={upload} />
                    }

                    {
                        uploading ?
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                marginTop: 30
                            }}>
                                <Item title="Total Request" desc={length} />
                                <Item title="Success Request" desc={completed} />
                            </div>
                            :
                            <div style={{
                                marginTop: 30,
                                fontSize: 13
                            }}>
                                <span onClick={() => {
                                    DownloadCsv("FlightBooking.csv",[["origin_city","destination_city","base_amount","igst","sgst","cgst","other_taxes","total_amount","invoice_number","invoice_date(DD-MM-YYYY)","vendor_code","sellergstin","flight_number","flight_code","guests","airline_name","pnr_number","departure_time(DD-MM-YYYY-HH-mm)","arrival_time(DD-MM-YYYY-HH-mm)"]])
                                }} style={{ color: 'var(--primary-color)', cursor: 'pointer', }}>Download Csv</span> for format
                            </div>
                    }
                </section>
            </>

        </SidePane>
    )
}

export default BulkUpload