import React from 'react';
import { TitleComponent } from '../../Common/TitleComponent';
import { CitySearch } from '../../Common/CitySearch';
import { FloatInput } from '../../../libs/FloatLabelInput';
import { DatesCompo } from '../../../libs/DatePicker';
import Moment from 'moment';
import { ChooseTraveller } from '../../Common/ChooseTraveller';
import { handleError, showToast } from '../../../libs/fToast/ToastCallback';
import { Attachment } from '../../../libs/Attachment';
import { get, post } from '../../../../Model/Network/Config/Axios';
import ButtonOutline from '../../../libs/ButtonOutline';
import BulkUpload from './BulkUpload';
import Employee from '../../Common/ChooseTraveller/Employee';
import { Modal } from '../../../libs/Modal/Modal';
import TripSearchable from '../../Common/TripSearchable';
import { SearchableSelect } from '../../../libs/Select/SearchableSelec2';
import { Select } from '../../../libs/Select';

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);
        this.state = {
            city: {
                value: '',
                id: ''
            },
            checkin: new Moment(),
            checkout: new Moment(),
            chooseEmployee: false,
            employees: [],
            invoiceDate: null,
            loading: false,
            BulkUploadBooking: false,
            paidByEmployee:false,
            paidByEmployeeModal:false,
            paidByEmployeeDetails:{},
            trip:undefined,
            itenaries:[],
            filteredItineraries:[],
            selectedItenerary:undefined,
            paidBy:undefined,
            allPaidByOptions:[{label:"Paid By Company",value:false},{label:"Paid By Employee",value:true}],
            paidByOptions:[{label:"Paid By Company",value:false},{label:"Paid By Employee",value:true}]
        }
    }


    mapEmployee = (item, index) => {
        let active = false;
        if (index % 2 === 0) {
            active = true
        }

        return (
            <div key={index} style={{ marginTop: 15, border: '1px solid #eee', borderRadius: 22, background: '#fafafa', padding: '12px 20px', height: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...active ? { marginRight: 15, width: '49%' } : { width: '49%' } }}>
                <p style={{ fontSize: 13, fontWeight: 600 }}>{item.name}</p>
                <p onClick={() => {
                    let list = this.state.employees.filter(val => val.reference !== item.reference);
                    this.setState({
                        ...this.state,
                        employees: list
                    })
                }} style={{
                    fontWeight: 300,
                    cursor: 'pointer'
                }}>Remove</p>
            </div>
        )
    }

    addEmployeeOfTrip=(val)=>{
        if(val && val.employee){
            let employees=[...this.state.employees];
            employees=employees.filter((item)=>{
                if(item && (item.trip || item.reference===val.employee.code)){
                    return false
                }
                return true;
            })
            employees.push({
                email: val.employee.email,
                name: val.employee.name,
                reference: val.employee.code,
                type : "EMPLOYEE",
                trip:true
            })
            this.setState((prev)=>{
                return {
                    ...prev,
                    employees,
                    paidByEmployee:true,
                    paidByEmployeeDetails:{
                        id:val.employee.id,
                        name: val.employee.name,
                    },
                    paidBy:{
                        label: "Paid By Employee",
                        value: true
                    }
                }
            })
        }
    }


    _newBooking = () => {

        if(this.state.loading)  return ;

        let city = this.state.city;
        let hotelName = this._hName.value();
        let amount = this._amount.value();
        let checkin = this.state.checkin;
        let checkout = this.state.checkout;
        let employees = this.state.employees;
        let id = this._id.value();
        let address = this._address.value();
        let iGst = this._igst.value();
        let sGst = this._sgst.value();
        let cGst = this._cgst.value();
        let base_amount = this._baseAmount.value();
        let invoiceNumber = this._invoiceNumber.value();
        let vendorCode = this._vendorCode.value();
        let sellergstin = this._gstin.value();
        let other_taxes = this._otherTax.value();
        let invoice_date = this.state.invoiceDate
        let tripId = this.state.trip;
        let paidByEmployee = this.state.paidByEmployee;

        if (!city) {
            showToast({
                type: 'error',
                message: 'Please enter valid city'
            })
            return
        }
        if (!hotelName) {
            showToast({
                type: 'error',
                message: 'Please enter valid hotel name'
            })
            return
        }
        if(!base_amount) {
            showToast({
                type: 'error',
                message: "Please enter valid base amount"
            })
            return;
        }

        if (!amount) {
            showToast({
                type: 'error',
                message: "Please enter valid total amount"
            })
            return;
        }

        if (!checkin) {
            showToast({
                type: 'error',
                message: 'Please enter valid checkIn date'
            })
            return
        }

        if (!checkout) {
            showToast({
                type: 'error',
                message: 'Please enter valid checkout date'
            })
            return
        }

        // if (!id) {
        //     showToast({
        //         type: 'error',
        //         message: 'Please enter valid id'
        //     })
        //     return
        // }

        // if (!address) {
        //     showToast({
        //         type: 'error',
        //         message: 'Please enter valid address'
        //     })
        //     return
        // }

        if (employees.length === 0) {
            showToast({
                type: 'error',
                message: 'Please enter employees details'
            })
            return
        }

        let guests = this.state.employees.map(val => val.email);
        let files = this.attachment.getValue();
        let invoiceFile = this.invoiceAttachment.getValue().length>0 ? this.invoiceAttachment.getValue()[0]:null;
        let sumOfBase = Number(base_amount)+Number(iGst)+Number(cGst)+Number(sGst)+ (other_taxes?parseFloat(other_taxes):0)||0
 
         if(Number(amount).toFixed(2)!==(sumOfBase.toFixed(2))){
            showToast({
                type:'error',
                message:"Total is not matching with base plus tax"
            })
            return;
        }

        let data = {
            guests,
            amount: +amount,
            id,
            name: hotelName,
            address,
            city: city.value,
            dates: {
                in: Moment(checkin).valueOf(),
                out: Moment(checkout).valueOf()
            },
            files,
            invoice:invoiceFile,
            base_amount: +base_amount,
            igst: +iGst,
            sgst: +sGst,
            cgst: +cGst,
            total_tax: +(Number(iGst)+Number(sGst)+Number(cGst)),
            other_taxes: other_taxes?parseFloat(other_taxes):0,
            paidByEmployee:false
        }

        if(invoiceNumber) data.invoice_no = invoiceNumber
        if(vendorCode) data.vendor_code = vendorCode
        if(sellergstin) data.seller_gstin = sellergstin
        if(invoice_date) data.invoice_date = Moment(invoice_date).valueOf();
        if(tripId){
            data.tripId=tripId.split('_')[1];
            data.tripDbId=Number(tripId.split('_')[0]);
            if(!this.state.selectedItenerary){
                showToast({
                    type:'error',
                    message:'Please Select Itenerary To Continue'
                })
                return;
            }
            data.tripItineraryId=this.state.selectedItenerary.value
        }
        if(paidByEmployee){
            data.paidByEmployee=paidByEmployee;
            data.paidByEmployeeId=this.state.paidByEmployeeDetails.id;
        }
        
            this.setState({
            ...this.state,
            loading:true
        })
        post(`hotels/import`, data, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'Ticket Create Successfully'
                })
                window.location.reload('/')
            }else if(e){
                handleError(e);
                this.setState({
                    ...this.state,
                    loading:false
                })
            }                
        })

    }

    render() {
        return (
            <div className="side-container">
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <TitleComponent title="Create Booking" subtitle="HOTEL" />
                    <ButtonOutline title="Bulk Upload" onClick={() => this.setState({...this.state,BulkUploadBooking:true})} />
                </div>
                <div style={{
                    marginTop: 20,
                    display: 'flex',
                    marginBottom: 10
                }}>
                    <div style={{ flex: 1 }}>
                        <p className="float-p-same" style={{ marginTop: 12 }}>City:</p>
                        <div style={{
                            position: 'relative'
                        }}>
                            <CitySearch style={{
                                height: 39
                            }} placeholder="City" airport={false} onCitySelected={city => {
                                this.setState({
                                    ...this.state,
                                    city
                                })
                            }} city={this.state.city} />
                        </div>
                    </div>
                </div>
                <div style={{ flex: 1, marginBottom: 8 }}>
                    <FloatInput title="Hotel Name" style={{ height: 39, marginBottom: 0 }} handleRef={ref => this._hName = ref} />
                </div>

                 <div style={{
                    display: 'flex',
                    marginBottom: 15
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 15
                    }}>
                        <FloatInput style={{height: 39 }} type="number" title="Base Amount" handleRef={ref => this._baseAmount = ref} />
                    </div>
                    <div style={{
                        flex: 1
                    }}>
                         <FloatInput style={{height: 39 }} type="number" title="IGST Amount" handleRef={ref => this._igst = ref} />
                    </div>
                </div>

                  
                <div style={{
                    display: 'flex',
                    marginBottom: 15
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 15
                    }}>
                        <FloatInput style={{height: 39 }} type="number" title="CGST Amount" handleRef={ref => this._cgst = ref} />
                    </div>
                    <div style={{
                        flex: 1
                    }}>
                         <FloatInput style={{height: 39 }} type="number" title="SGST Amount" handleRef={ref => this._sgst = ref} />
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    marginBottom: 15
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 15
                    }}>
                        <FloatInput style={{
                        height: 39
                    }} type="number" title="Other Tax" handleRef={ref => this._otherTax = ref} />
                    </div>
                    <div style={{
                        flex: 1
                    }}>
                        <FloatInput style={{
                        height: 39
                    }} type="number" title="Total Amount" handleRef={ref => this._amount = ref} />
                    </div>
                </div>

                <div style={{ display: 'flex', marginBottom: 15,alignItems:'center'}}>
                    <div style={{ flex: 1, marginRight: 15 }}>
                        <FloatInput
                            style={{ height: 39 }}
                            type="text"
                            title="Invoice Number"
                            handleRef={ref => this._invoiceNumber = ref}
                        />
                    </div>
                    <div style={{ flex: 1, }}>
                        <p style={{
                        }} className="float-p-same">Invoice Date:</p>
                        <DatesCompo selectDate={this.state.invoiceDate} handleDate={invoiceDate => {
                            this.setState({
                                ...this.state,
                                invoiceDate
                            })
                        }} />
                    </div>
                </div>
         
                <div style={{ display: 'flex', marginBottom: 15 }}>
                    <div style={{ flex: 1,marginRight: 15  }}>
                        <FloatInput
                            style={{ height: 39 }} t
                            ype="text"
                            title="Seller GSTIN"
                            handleRef={ref => this._gstin = ref}
                        />
                    </div>
                    <div style={{ flex: 1 ,marginRight: 15 }}>
                        <FloatInput
                            style={{ height: 39 }}
                            type="text"
                            title="Vendor Code"
                            handleRef={ref => this._vendorCode = ref}
                        />
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    marginBottom: 10
                }}>
                    <div style={{ flex: 1, marginRight: 15 }}>
                        <p style={{
                        }} className="float-p-same">Check in:</p>
                        <DatesCompo selectDate={this.state.checkin} handleDate={checkin => {
                            this.setState({
                                checkin
                            })
                        }} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <p style={{
                        }} className="float-p-same">Check out:</p>
                        <DatesCompo selectDate={this.state.checkout} handleDate={checkout => {
                            this.setState({
                                checkout
                            })
                        }} />
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    marginBottom: 8
                }}>
                    <div style={{ flex: 1, marginRight: 15 }}>
                        <FloatInput style={{ height: 39 }} type="text" title="id" handleRef={ref => this._id = ref} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <FloatInput style={{ height: 39 }} type="text" title="Address" handleRef={ref => this._address = ref} />
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    marginBottom: 8
                }}>
                    <div style={{ flex: 1, marginRight: 15 }}>
                        <TripSearchable addEmployeeOfTrip={this.addEmployeeOfTrip} onSelect={trip=>{
                            this.setState((prev)=>({...prev,trip:trip,selectedItenerary:undefined}))
                            get(`trip/${trip.split('_')[1]}/trip.itinerary`,(e,r)=>{
                                if(r){                                    
                                    let items=r.itineraries.filter((item)=>{
                                        if(item && item.stay && item.stay.toLowerCase()==="hotel"){
                                            return true;
                                        }
                                        return false;
                                    }).map(item=>({label:`${item.current.name}`,value:item.id,des:`End Date : ${item.end ? item.end.date : '-'}`,des1:`Travel : ${item.travel}, Stay : ${item.stay}`}))
                                    this.setState((prev)=>({...prev,
                                        itenaries:items,
                                        filteredItineraries:items
                                    }))
                                }
                            })
                        }} />
                    </div>
                    <div style={{ flex: 1 }} >
                        {
                            this.state.trip ?

                            <SearchableSelect
                                items={this.state.filteredItineraries}
                                onChange={(val)=>{
                                    if(val.length){
                                        const filteredData=this.state.itenaries.filter(item=>JSON.stringify(item).includes(val));
                                        this.setState((prev)=>({...prev,filteredItineraries:filteredData}));
                                    }else{
                                        this.setState(prev=>({...prev,filteredItineraries:this.state.itenaries}));
                                    }
                                }}
                                label={"Itenararies"}
                                placeholder={"Select Itenerary"} 
                                select={(val)=>this.setState(prev=>({...prev,selectedItenerary:val}))} 
                                value={this.state.selectedItenerary}
                            />

                            :  null
                        }
                    </div>
                </div>
                <Select
                    placeholder="Paid By"
                    value={this.state.paidBy}
                    items={this.state.paidByOptions}
                    select={(val)=>{
                        if(val.value){
                            this.setState((prev)=>{
                                return {
                                    ...prev,
                                    paidByEmployee:true,
                                    paidByEmployeeModal:(this.state.trip)?false:true,
                                    paidByEmployeeDetails:this.state.paidByEmployeeDetails,
                                    ...(this.state.trip)?{paidBy:{label:"Paid By Employee",value:true}}:{}
                                }
                            })
                        }
                        else{
                            this.setState((prev)=>{
                                return {
                                    ...prev,
                                    paidBy:val,
                                    paidByEmployee:false,
                                    paidByEmployeeModal:false,
                                }
                            })
                        }
                    }}
                    onChange={(val)=>{
                        let items=this.state.allPaidByOptions.filter((item)=>{
                            return String(item.label).toLowerCase().includes(String(val).toLowerCase());
                        })
                        this.setState((prev)=>{
                            return {...prev,paidByOptions:items}
                        })
                    }}
                />

                {this.state.paidByEmployee && <FloatInput style={{ height: 39, marginBottom:10 }} disabled={true} type="text" title="Paid By" value={this.state.paidByEmployeeDetails.name} />}
                

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {
                        this.state.employees.map(this.mapEmployee)
                    }
                    <div onClick={() => {
                        this.setState({
                            ...this.state,
                            chooseEmployee: true
                        })
                    }} style={{
                        display: 'flex',
                        marginTop: 15,
                        alignItems: 'center',
                        background: "#fafafa",
                        border: '1px solid #eee',
                        borderRadius: 22,
                        position: 'relative',
                        padding: '12px 20px',
                        width: '49%',
                        cursor: 'pointer',
                        flexDirection: 'row'
                    }}>
                        <p className="label-back" style={{
                            fontWeight: 400,
                            color: "#555555",
                            fontSize: 13,
                            position: 'absolute',
                            top: -8,
                            zIndex: 11,
                            left: 20,
                            marginRight: 10
                        }}>
                            Traveller
                        </p>
                        <p style={{
                            fontWeight: 600,
                            color: "#454545",
                            fontSize: 15,
                        }}>{"Add Traveller"}</p>
                    </div>
                </div>

                {
                    this.state.paidByEmployeeModal ? 
                    <Modal
                        style={{
                            save: {
                                width: '100%',
                                height: '45px',
                                display: 'flex',
                                justifyContent: 'center',
                                textTransform: 'uppercase',
                                alignItems: 'center'
                            }
                        }}
                        hideButton={true}
                        des="Select Employee"
                        show={this.state.paidByEmployeeModal}
                        title="Select Employee" 
                        close={() => {
                            this.setState({
                                ...this.state,
                                paidByEmployeeModal: false,
                                paidByEmployeeDetails: {},
                                paidByEmployee: false,
                            })
                        }}>

                        <Employee callback={data => {
                            this.setState({
                            ...this.state,
                            paidByEmployeeDetails:{
                                name:data.employee.label,
                                id:data.employee.id
                            },
                            paidByEmployeeModal:false,
                            paidByEmployee:true,
                            paidBy:{label:"Paid By Employee",value:true}
                        })}} />
                    </Modal>
                    : null
                }

                <Attachment
                    handleRef={ref => this.attachment = ref}
                />
                <Attachment
                    title='Add Invoice'
                    handleRef={ref => this.invoiceAttachment=ref}
                    singleFileAttachment={true}
                />
                <ChooseTraveller shown={this.state.chooseEmployee} close={() => this.setState({
                    ...this.state,
                    chooseEmployee: false
                })} callback={data => {
                    let arr = this.state.employees.filter(val => val.reference !== data.reference);

                    arr.push(data)
                    this.setState({
                        ...this.state,
                        employees: [...arr],
                        chooseEmployee: false
                    })

                }} />
                <div onClick={this._newBooking} className="btn btn-portlet" style={{
                    marginTop: 50,
                    height: 42,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }} >{ this.state.loading ? <div className="loader" />: 'Create Booking'}</div>

                {this.state.BulkUploadBooking && <BulkUpload onClose={()=>this.setState({...this.state,BulkUploadBooking:false})}/>}
            </div>
        );
    }
}

export default Index;