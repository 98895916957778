import React from 'react'
import Moment from 'moment'
import HotelSearch from './desk.png'
import {showToast} from "../../../libs/Toast/ToastCallback";
import {CitySearch} from '../../Common/CitySearch';
import {DatesCompo} from '../../../libs/DatePicker';


class SearchComponent extends React.Component {

	state = {
		suggestions : [],
		originCity: {
			text:"",
			value:""
		},
		destinationCity: {
			text:"",
			value:""
		},
		date:new Moment(),
		notes : []
	}

	componentDidMount(){
		this.props.loader(r=>{
			this.setState({
				...this.state,
				originCity: { 
					value: r.originName,
					id: r.originId
				},
				destinationCity: {
					value: r.destinationName,
					id: r.destinationId
				},
				requestId: r.requestId,
				date:Moment(r.date,"DD-MM-YYYY"),
				notes : r.notes,
				options:r.options
			})
		})
	}

	search = () => {

		let origin = this.state.originCity;
		if(!origin.id) {
			showToast({
				type:'error',
				message:'Please select Origin City'
			})
		}

		let destination  = this.state.destinationCity;
		if(!destination.id) {
			showToast({
				type:'error',
				message:'Please select Destination City'
			})
		}

		let date = this.state.date;
		if(!date || date === "") {
			showToast({
				type:'error',
				message:'Please Select Vaid Date'
			})
		}

		let data = {
			origin:origin.id,
			destination:destination.id,
		}

		let route  = {
			route:data,
			date:Moment(date).format("DD-MM-YYYY")
		}

		this.props.search(route)
		
	}

	mapNotes = (item,index) => {
		return(
			<li style = {{fontSize:12,padding:'8px 5px',color:'#7F7AA2'}} key = {index} >{item}</li>
		)
	}

	render() {
		return (
			<div style={{
				width:325,
				overflowX:'hidden',
				background:"white",
				padding:"25px 18px",
				height:'100vh',
				overflowY:'auto',
				borderRight:'1px solid #dedede'
			}}>
			 <div>
				<h5 className="prime-color" style={{
					fontSize:25,
					fontWeight:800,
					textAlign:'center',
					letterSpacing:1,
					lineHeight:"35px",
					marginTop:15
				}}>
					Book Bus Online
				</h5>
				<p style={{
					fontSize:13,
					color:"#BABBCC",
					textAlign:'center',
					lineHeight:"16px"
				}}>
					Please add below details and click on button below to search flights.
				</p>
				<div style={{
					width:'calc(100% + 36px)',
					marginLeft:-18,
					marginRight:-18,
					marginTop:30,
				}}>
					<img src={HotelSearch} alt="hotel search" style={{
						width:'100%'
					}}/>
					
				</div>
						<p style = {{
							fontSize:11,
							color:"#555555",
							fontWeight:600,
							marginBottom:4,
							marginTop:18
							}}> 
								Origin City
						</p>
					<div style = {{position:'relative'}}>
						
						<CitySearch disabled={this.state.requestId} onCitySelected = { originCity => {
							this.setState({
								...this.state,
								originCity
							})}}
							city = {this.state.originCity}
						 />
					</div>
					<p style = {{marginTop:16,fontSize:11,marginBottom:4,fontWeight:600}}>Destination City</p>
					<div style = {{position:'relative'}}>
						<CitySearch disabled={this.state.requestId} onCitySelected = { destinationCity => {
							this.setState({
								...this.state,
								destinationCity
							}) }}
							city = {this.state.destinationCity}
							/>
					</div>
					<div style = {{position:'relative',marginTop:16}}>
						 <p style ={{
							 marginTop:16,
							 marginBottom:4,
							 fontWeight:600,
							 color:"#555555",
							 fontSize:11
						 }}>
						 	Date
						 </p>
						 <DatesCompo disabled={this.state.requestId} selectDate = {this.state.date} handleDate = { date => {
							 this.setState({
								 date
							 })
						 }} />
					</div>

					<div style = {{position:'relative',marginTop:16}}>
					</div>
				
					<p className="prime-background" style={{
						textAlign:'center',
						textTransform:'uppercase',
						color:"white",
						fontWeight:600,
						padding:'10px 20px',
						fontSize:15,
						marginTop:35,
						background:"#7558F6",
						cursor:'pointer'
					}} onClick={this.search}>
						Search Buses
					</p>
					
					{ this.state.options ? <p style = {{fontSize:12,fontWeight:600,marginTop:25}}>Employee does have an option to choose from</p> : null}
					{
						this.state.notes.length > 0 ?
						(	<div>
								<p style = {{fontSize:12,fontWeight:600,color:'#555555',marginTop:25}}>Notes:</p>
								<div style = {{backgroundColor:'#F6F6FF',padding:'8px 0',marginTop:35,borderRadius:4}}>
									<ul style = {{paddingLeft:25,margin:'10px 0'}}>
										{
											this.state.notes.map(this.mapNotes)
										}
									</ul>
								</div>
							</div>
						) : null
					}
				</div>
			</div>
		)
	}

}

export default SearchComponent