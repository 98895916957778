import React, { useState, useEffect } from "react";
import { get } from "../../../../Model/Network/Config/Axios";
import { showToast } from "../../../libs/Toast/ToastCallback";
import { Modal } from "../../../libs/Modal/Modal";
import { SearchableSelect } from "../../../libs/Select/SearchableSelec2";
import { PageLoader } from "../../../libs/Loader/PageLoader";

function ViewAdmin(props) {
    const [allAdmins,setAllAdmins] = useState([]);
    const [admins,setAdmins] = useState([]);
    const [selectedAdmin,setSelectedAdmin] = useState(undefined);
    const [currentAdmin,setCurrentAdmin] = useState(undefined);
    const [loading,setLoading] = useState(true);

    useEffect(()=>{
        if(props && props.item && props.item.adminId && allAdmins && allAdmins.length){
            for(let i=0;i<allAdmins.length;i++){
                if(allAdmins[i].value===props.item.adminId){
                    setCurrentAdmin(allAdmins[i]);
                    break;
                }
            }
        }
    },[props,allAdmins])

    useEffect(()=>{
        setLoading(true)
        get(`/desks`,(e,r)=>{
            if(r){
                let items=r.travelDesks.map((item)=>{
                return {
                        label : item.name,
                        des   : item.email,
                        value : item.id
                    }
                })
                setAllAdmins(items);
                setAdmins(items);
                setLoading(false)
            }
            else{
                showToast({
                    type: "error",
                    message: e.data.message,
                })
            }
        })
    },[])

    if(loading){
        return <Modal title="Booking Request Admin" close={() => {props.onClose()}} hideButton={true} des=""
        style={{
            save: {
                width: '100%',
                textAlign: 'center',
                fontSize: 17,
                padding: "12px 20px",
                background: "#7558F6",
                border: "1px solid #7558F6"
            },
        }} show={true}>
            <PageLoader/>
        </Modal>
    }

    return (
        <Modal
            buttonName={loading?"Loading...":!currentAdmin?"Assign":"Unassign"}
            des=""
            title="Booking Request Admin"
            close={() => {
                props.onClose();
            }}
            style={{
                save: {
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 17,
                    padding: "12px 20px",
                    background: "#7558F6",
                    border: "1px solid #7558F6"
                },
            }} show={true} onSave={()=>{
                if(!loading){
                    if(currentAdmin){
                        setLoading(true);
                        get(`/${props.item.id}/unAssign.me`,(e,r)=>{
                        if(r){
                            showToast({
                                type: 'success',
                                message: "Admin unassigned successfully"
                            })
                            setLoading(false)
                            props.onClose();
                            props.init();
                        }
                        else if(e){
                            setLoading(false)
                            showToast({
                                type: 'error',
                                message: 'Could not unassign admin for the booking request'
                            })
                            return;
                        }
                        })
                    }
                    else{
                        if(!(selectedAdmin && selectedAdmin.value)){
                            showToast({
                                type: 'error',
                                message: 'Select the admin'
                            })
                            return
                        }
                        setLoading(true);
                        get(`/${props.item.id}/assign.me?adminId=${selectedAdmin.value}`,(e,r)=>{
                        if(r){
                            showToast({
                                type: 'success',
                                message: "Admin assigned successfully"
                            })
                            setLoading(false)
                            props.onClose();
                            props.init();
                        }
                        else if(e){
                            setLoading(false)
                            showToast({
                                type: 'error',
                                message: 'Could not assign admin to the booking request'
                            })
                            return;
                        }
                        })
                    }   
                }
            }}>
            <div>
                {(currentAdmin)?<div style={{marginTop:'1rem',marginBottom:'1rem'}}>
                    Selected Admin : <div style={{fontWeight:'bold'}}>{currentAdmin.label} ({currentAdmin.des})</div>
                </div>:null}
                {(allAdmins && !currentAdmin)?<SearchableSelect
                                items={admins}
                                onChange={(val)=>{
                                    let items=allAdmins.filter((item)=>{
                                    return JSON.stringify(item).toLowerCase().includes(val.toLowerCase())
                                    })
                                    setAdmins(items)
                                }}
                                label="Select Admin"
                                placeholder={"Select Admin"} 
                                value={selectedAdmin}
                                select={setSelectedAdmin}/>:null}
            </div>
        </Modal>
    );
}

export default ViewAdmin;
