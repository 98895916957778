import React from 'react';
// import {Headings} from '../../../../../../../retro'
import moment from 'moment'
import StatusChip from './StatusChip/StatusChip';

function Trip({ title, titleOne, titleTwo, description, bus = 0, destination, createdOn, endDate, status, open, approved, flight = 0, cab = 0, hotel = 0, image, origin, startDate, train = 0, tripId, type, onClick, permissionId, setShowTripDetails }) {

    if (!tripId)
        return <></>

    let timeToTravel = startDate - moment().startOf('day').valueOf()
    let withinAWeek = timeToTravel > 0 && timeToTravel < 86400000 * 7
    let today = timeToTravel === 0
    return (
        <>
            <div onClick={setShowTripDetails} style={{
                minWidth: 260, maxWidth: 260,
                animation: 'fadeInUp 0.5s ease-in-out',
                border: '1px solid #ccc',
                // display: 'none',
                margin: '4px',
                padding: '0',
                flex: '1',
                cursor: 'pointer',
            }}>
                <div style={
                    {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                        position: 'relative',
                    }
                }>
                    <div style={
                        {
                            position: 'relative',
                            // display: 'none',
                            width: '100%',
                            background: 'url(/path-to-curles-bg.png) no-repeat center center / cover',
                        }
                    }>
                        <div style={{ height: 120, backgroundImage: `url(${image})`, backgroundPosition: 'center', backgroundSize: 'cover', width: '100%' }} />
                        {open &&
                            <div className='text-small text-white absolute h-100 w-100 pd1' style={{
                                left: 0, right: 0, top: 0, background: 'rgba(0,0,0,0.7)',
                                fontSize: '0.875rem',
                                color: 'white',
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                padding: '4px',
                            }}>
                                <h6><i className='fas fa-info-circle mb1' /></h6>
                                This trip is completed but not closed
                            </div>
                        }
                    </div>
                    <div style={{
                        padding: '8px',
                        width: '100%',
                    }}>
                        <div style={{
                            fontWeight: 'bold',
                            fontSize: '0.875rem',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }} >{tripId}</div>
                        <h6 style={
                            {
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                marginBottom: '4px',
                            }
                        }>{title}</h6>
                        {titleOne && <div style={{ fontSize: '0.875rem', }}>{titleOne.replaceAll('.', ', ')}</div>}
                        {titleTwo && <div style={{ fontSize: '0.875rem', }}>{titleTwo.replaceAll('.', ', ')}</div>}
                        <div style={{
                            fontSize: '0.875rem',
                            color: '#6c757d'
                        }}>{description}</div>
                        {status && <StatusChip className='w-auto absolute' style={{ top: '0.7rem', left: '0.7rem' }} status={status} />}
                        {!!permissionId && <StatusChip className='w-auto absolute' style={{ top: '2.7rem', left: '0.7rem' }} status={"Approval Pending"} />}

                        {
                            (withinAWeek || today) &&
                            <div className={`w-auto absolute border-none`} style={{ top: '0.7rem', right: '0.7rem', padding: '0.4rem 0.8rem', background: today ? 'var(--theme-success-color)' : 'var(--theme-primary-color)' }} status={status}>
                                {withinAWeek
                                    ? <>THIS WEEK<div className='ml1' style={{ width: 10, height: 10, borderRadius: 15, background: '#fff' }} /></>
                                    : <>TODAY<img src={"/assets/bell.gif"} alt='bell' className='ml1' style={{ width: 13, height: 13 }} /></>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Trip;
