import { get, post } from "../../../../Model/Network/Config/Axios";


class Model {

    constructor() {
        this.Disposables = [];
    }

    destroy = () => {
        this.Disposables.forEach(item => {
            if (item.isCancellable())
                item.cancel()
        })
    }


    fetch = (id, cb) => {
        this.Disposables.push(get(`/bookings/${id}`, cb));
    }

    //fetchIfFailed logic needs to removed after the new api is deployed on prod, api -> /requests/transaction/${id} required later on
    fetchIfFailed = (id, cb) => {
        this.Disposables.push(get(`/requests/transaction/${id}`, cb));
    }

    sendEmail = (data, id, cb) => {
        this.Disposables.push(post(`/bookings/${id}/email`, data, cb));
    }

    createSupportTicket = (data, cb) => {
        this.Disposables.push(post(`/support/flights`, data, cb));
    }

}

export default Model