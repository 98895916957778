import React, { useEffect, useState } from 'react'
import { get } from '../../../Model/Network/Config/Axios'
import { TitleComponent } from '../Common/TitleComponent'
import TripCard from "../Common/TripCard"
import TripDetails from '../Common/BookingRequestItems/TripDetails'
import Search from '../../libs/Search/Search'
import { SearchableSelect } from '../../libs/Select/SearchableSelec2'

const TripList = ({ api, title, status }) => {
    const [trips, setTrips] = useState([])
    const [filteredTrips, setFilteredTrips] = useState([])
    const [loading, setLoading] = useState(true)
    const [showTripDetails, setShowTripDetails] = useState(false)
    const [query, setQuery] = useState()
    const [selectedStatus, setSelectedStatus] = useState({label:"NONE",value:'NONE'});

    const OPTIONS = [{label:"NONE",value:'NONE'},{label:"PENDING",value:'PENDING'},{label:"COMPLETED",value:'COMPLETED'}];

    const Init = () => {
        console.log('api', api)
        get(api, (e, r) => {
            if (r) {
                if(status){
                    if(status==='Past') setTrips(r.trips.filter(trip=>trip.status==='Past Trip'))
                    else    setTrips(r.trips.filter(trip=>trip.status!=='Past Trip'))   
                }else{
                    setTrips(r.trips)
                }
                setLoading(false)
            }
        })
    }

    useEffect(()=>{

        if(trips && trips.length){
            setFilteredTrips(trips);
        }
    },[trips])

    useEffect(()=>{

        let filteredTrips=[...trips];

        if(query){
            filteredTrips=filteredTrips.filter(trip => JSON.stringify(trip).toLocaleLowerCase().includes(query.toLowerCase()));
        }
        if(selectedStatus && selectedStatus.value!=='NONE'){
            
            if(selectedStatus.value==='PENDING'){
                filteredTrips=filteredTrips.filter(trip => trip.allowVoucherCreation==='OFF');
            }else{
                console.log('object');
                filteredTrips=filteredTrips.filter(trip => trip.allowVoucherCreation==='ON');
            }
        }
        setFilteredTrips(filteredTrips)
    },[trips,query, selectedStatus])

    useEffect(() => {
        Init();
    }, [api])

    if (loading) {
        return <div className="loader" />
    }

    return (
        <div className="side-container">
            <TitleComponent subtitle={"Trips"} title={`${title} Trip`} />
            <div style={{display:'flex',alignContent:'center'}} >
                <Search styles={{height:'38px',alignSelf:'flex-end',width:'250px'}} placeholder="Search Trip" onChange={(e)=>setQuery(e.target.value)} query={query} />
                {title!=='Deleted' && <div style={{width:'300px',marginLeft:'20px'}} >
                    <SearchableSelect 
                        items={OPTIONS}
                        onChange={()=>{}}
                        label={"Travel Admin Action"}
                        placeholder={"Travel Admin Action"} 
                        select={(val)=>{
                            setSelectedStatus(val);
                        }} 
                        value={selectedStatus ? selectedStatus : " "}
                    />
                </div> }
            </div>
            <div style={{marginTop:'30px',display:'flex', flexWrap:'wrap', justifyContent:'flex-start', gap:'20px'}}>
                {
                    filteredTrips.map((trip, index) => {
                        return (
                            <TripCard setShowTripDetails={()=>setShowTripDetails(trip.tripId)} key={index} {...trip} onClick={() => { }} />
                        )
                    }) 
                }
            </div>
            {
				showTripDetails ?
					<TripDetails reload={()=>Init()} onClose={()=>setShowTripDetails()} tripId={showTripDetails}/>
				:null
			}
        </div>

    )
}

export default TripList