import React, { useState, useEffect } from "react";
import { SidePane } from "../../../libs/fSidePane/RightPane";
import Moment from "moment";
import { get } from "../../../../Model/Network/Config/Axios";
import { FloatInput } from "../../../libs/FloatLabelInput";
import { PageLoader } from "../../../libs/Loader/PageLoader";
import TabSwtich from "../../../libs/fTabSwitch";
import { showToast } from "../../../libs/Toast/ToastCallback";
import ToggleSwitch from "../../../libs/fSwitch";
import { KeyValue } from "../KeyValue";
import moment from "moment";

function TripDetails(props) {

  const [itinerary, setItinerary] = useState();
  const [details, setDetails] = useState();
  const [comments, setComments] = useState();
  const [getTab, setTab] = useState(1);
  const [config,setConfig] = useState({});

  useEffect(() => {
    fetchTripDetails();
    init();
  }, []);

  const init = () => {
		get(`/config/counts`,(e,r)=>{
			if(r){
				setConfig(r);
			}else{
        showToast({
          type: "error",
          message: e.data.message,
        })
      }
		})
	}

  const fetchTripDetails = () => {
    const tripId = props.tripId;
    get(`/requests/${tripId}/trip.details`, (e, r) => {
      if (r) {
        setDetails(r);
        fetchComments(r.id);
      } else {
        showToast({
          type: "error",
          message: e.data.message,
        });
      }
    });

    get(`/requests/${tripId}/trip.itinerary`, (e, r) => {
      if (r) {
        setItinerary(r);
      }
    });
  };

  const fetchComments = (id) => {
    get(`/requests/${id}/trip.permissionDetails`, (e, r) => {
      if (r) {
        if (r.permissionsDetails.length)
          setComments(r.permissionsDetails[0].comments);
      }
    });
  };

  const handleToggle = (val) => {
    get(`requests//trip/toggle?toggle=${val?'ON':'OFF'}&tripId=${props.tripId}`,(e,r)=>{
      if(r){
        fetchTripDetails()
        props.reload();
      }else{
        showToast({
					type: 'error',
					message: e.data.error
				})
      }
    })
  }

  const currentDateEqualsEndDate=(end)=>{
    const endDate=new Date(end);
    const currentDate=new Date();

    if(currentDate>endDate) return true;

    return false;
  }

  const tabs = [
    {
      name: "Details",
      active: getTab === 1,
      onClick: () => {
        setTab(1);
      },
    },
    {
      name: "Comments",
      active: getTab === 2,
      onClick: () => {
        setTab(2);
      },
    },
  ];

  return (
    <SidePane button={false} onClose={props.onClose}>
      {details && itinerary ? (
        <>
          <div
            style={{color: "var(--text-color)",fontWeight: 700,fontSize: "28px",lineHeight: "1.5",marginBottom: "25px"}}
          >
            {details.tripId}
          </div>

          <TabSwtich style={{ marginBottom: "1rem" }} tabs={tabs} />

          {getTab === 1 ? (
            <>
              {config.allowVoucherCreationFeatureByTravelAdmin && currentDateEqualsEndDate(details.endDate) ?
                <ToggleSwitch style={{ margin:'20px 0 20px -10px' }} label="Allow voucher creation by travel admin" status={(details.allowVoucherCreation==="ON")?true:false} callback={handleToggle} />
                : null}
              <div
                style={{color: "var(--text-color)",fontWeight: 600,fontSize: "24px",lineHeight: "1.5",marginBottom: "25px"}}
              >
                Trip Details 
              </div>
              <hr style={{ borderColor: "var(--border-color)" }} />
              {details.title && (
                <FloatInput
                  labelStyle={{fontSize: "15px",fontWeight: 620,color: "var(--text-color)"}}
                  style={{display: "block",border: "none",backgroundColor: "var(--input-background-color)"}}
                  inputStyle={{fontSize: "14px",fontWeight: 400,color: "var(--text-color)"}}
                  title="Title"
                  value={details.title}
                  type="text"
                  disabled
                />
              )}
              {details.budget && (
                <FloatInput
                  labelStyle={{fontSize: "15px",fontWeight: 620,color: "var(--text-color)"}}
                  style={{display: "block",border: "none",backgroundColor: "var(--input-background-color)"}}
                  inputStyle={{fontSize: "14px",fontWeight: 400,color: "var(--text-color)"}}
                  title="Budget"
                  value={`${details.budget} INR`}
                  type="text"
                  disabled
                />
              )}
              {details.status && (
                <FloatInput
                  labelStyle={{fontSize: "15px",fontWeight: 620,color: "var(--text-color)"}}
                  style={{display: "block",border: "none",backgroundColor: "var(--input-background-color)"}}
                  inputStyle={{fontSize: "14px",fontWeight: 400,color: "var(--text-color)"}}
                  title="Status"
                  value={details.status}
                  type="text"
                  disabled
                />
              )}
              {details.pettyAmount && (
                <FloatInput
                  labelStyle={{fontSize: "15px",fontWeight: 620,color: "var(--text-color)"}}
                  style={{display: "block",border: "none",backgroundColor: "var(--input-background-color)"}}
                  inputStyle={{fontSize: "14px",fontWeight: 400,color: "var(--text-color)"}}
                  title="Petty Amount"
                  value={details.pettyAmount}
                  type="text"
                  disabled
                />
              )}
              {details.startDate && (
                <FloatInput
                  labelStyle={{fontSize: "15px",fontWeight: 620,color: "var(--text-color)"}}
                  style={{display: "block",border: "none",backgroundColor: "var(--input-background-color)"}}
                  inputStyle={{fontSize: "14px",fontWeight: 400,color: "var(--text-color)"}}
                  title="Start Date"
                  value={Moment(details.startDate).format("DD MMM YYYY")}
                  type="text"
                  disabled
                />
              )}
              {details.endDate && (
                <FloatInput
                  labelStyle={{fontSize: "15px",fontWeight: 620,color: "var(--text-color)"}}
                  style={{display: "block",border: "none",backgroundColor: "var(--input-background-color)"}}
                  inputStyle={{fontSize: "14px",fontWeight: 400,color: "var(--text-color)"}}
                  title="End Date"
                  value={Moment(details.endDate).format("DD MMM YYYY")}
                  type="text"
                  disabled
                />
              )}
                 {details && details.guestEmail && (
                    <>
                  <div style={{ color: "var(--text-color)", fontWeight: 600, fontSize: "24px", lineHeight: "1.5", marginBottom: "25px", marginTop: "25px" }}>
                    Guest Details
                  </div>
                        <div className='border rounded-md pd2 mt2 text-small'>
                            <KeyValue title="Guest Name" value={`${details.guestName}`} />
                            <KeyValue title="Guest Email" value={`${details.guestEmail}`} />
                            <KeyValue title="Gender" value={`${details.guestGender}`} />
                            {details.guestDob && (
                                <KeyValue title="DOB" value={`${moment(details.guestDob).format("DD MMM YYYY")}`} />
                            )}
                        </div>
                    </>
                )}
              <div
                style={{color: "var(--text-color)",fontWeight: 600,fontSize: "24px",lineHeight: "1.5",marginBottom: "25px",marginTop: "25px"}}
              >
                Itinerary Details
              </div>
              <div
                style={{height: 45,background: "#bdbdbd",border: "1px solid var(--border-color)",display: "flex",borderTopLeftRadius: 4,borderTopRightRadius: 4}}
              >
                {[
                  {
                    name: "City",
                    flex: 1,
                    style: {
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "#212121",
                    },
                  },
                  {
                    name: "Travel / Stay Option",
                    flex: 1,
                    style: {
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "#212121",
                    },
                  },
                  {
                    name: "Dates",
                    flex: 1,
                    style: {
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "#212121",
                    },
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    style={{
                      flex: item.flex,
                      ...item.style,
                      height: 40,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#bdbdbd",
                    }}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
              {itinerary.itineraries.map((item, index) => (
                <div
                  key={index}
                  style={{maxHeight: 105,border: "1px solid var(--border-color)",display: "flex",flex: 1,fontSize: "11px",backgroundColor: "#f0f0f0",marginBottom: "5px"}}
                >
                  {item.current ? (
                    <div
                      style={{maxHeight: 100,padding: "5px",display: "flex",borderRight: "1px solid var(--border-color)",justifyContent: "center",alignItems: "center",flex: 1,textAlign: "center",color: "#424242"}}
                    >
                      {item.current.name}
                    </div>
                  ) : null}

                  <div
                    style={{maxHeight: 100,padding: "5px",borderRight: "1px solid var(--border-color)",justifyContent: "center",alignItems: "center",flex: 1,flexDirection: "column",textAlign: "center",color: "#616161"}}
                  >
                    {item.travel ? <div style={{marginBottom:'2px'}} >TRAVEL: {item.travel}</div> : null}
                    {item.stay ? <div style={{marginBottom:'2px'}} >STAY: {item.stay}</div> : null}
                    {item.hotelTitle ? (
                      <div style={{marginBottom:'2px'}} >HOTEL: {item.hotelTitle}</div>
                    ) : null}
                  </div>

                  <div
                    style={{maxHeight: 100,padding: "5px",display: "flex",borderRight: "1px solid var(--border-color)",justifyContent: "center",alignItems: "center",flex: 1,flexDirection: "column",textAlign: "center",color: "#424242"}}
                  >
                    <div>
                      {item.start ? `Start Date: ${item.start.date}` : ""}
                    </div>
                    <div>{item.end ? `End Date: ${item.end.date}` : ""}</div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
          {getTab === 2 ? (
            <>
              {comments ? (
                <>
                  {comments.map((comment, index) => (
                    <div
                      key={index}
                      style={{marginBottom: "15px",padding: "10px",border: "1px solid #dedede",borderRadius: "4px"}}
                    >
                      <div
                        style={{display: "flex",justifyContent: "space-between",marginBottom: "10px"}}
                      >
                        <p style={{ margin: "0 0 5px", fontWeight: 600 }}>
                          {comment.owner.ownerName}
                        </p>
                        <p
                          style={{margin: "0",fontSize: "12px",color: "#666",}}
                        >
                          {Moment(comment.time).format("DD MMM YYYY, h:mm a")}
                        </p>
                      </div>
                      <p
                        style={{margin: "0 0 5px",fontWeight: 400,fontSize: "13px"}}
                      >
                        {comment.message}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <div
                  style={{color: "var(--text-color)",fontWeight: 600,fontSize: "24px",lineHeight: "1.5"}}
                ></div>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <PageLoader />
      )}
    </SidePane>
  );
}

export default TripDetails;
