import React from 'react'
import Moment from 'moment'
import HotelSearch from './hotelsearch.png'
import { showToast } from "../../../libs/Toast/ToastCallback";
import { CitySearch } from '../../Common/CitySearch';
import { DatesCompo } from '../../../libs/DatePicker';
import { ShownSelect } from '../../../libs/Select/index3';
//import { get,set } from '../../../../Utils/Crypto';




class SearchComponent extends React.Component {

	state = {
		suggestions: [],
		originCity: {
			value: "",
			id: undefined
		},
		destinationCity: {
			value: "",
			id: undefined
		},
		date: new Moment(),
		notes: [],
		adult: 1,
		type: {
			label: 'Economy',
			value: 'economy'
		}
	}



	componentDidMount() {
		this.props.loader(r => {
			this.setState({
				...this.state,
				originCity: {
					value:r.originName,
					id:  r.originAirportCode
				},
				destinationCity: {
					value: r.destinationName,
					id:   r.destinationAirportCode
				},
				requestId: r.requestId,
				date: Moment(r.date, "DD-MM-YYYY"),
				forms:r.forms
			})
		})
	}

	search = () => {

		let origin = this.state.originCity;
		if (!origin.id) {
			showToast({
				type: 'error',
				message: 'Please select Origin City'
			})
			return;
		}

		let destination = this.state.destinationCity;
		if (!destination.id) {
			showToast({
				type: 'error',
				message: 'Please select Destination City'
			})
			return;
		}

		let TIME = Moment('12:00 PM', 'hh:mm A');


		if (this.state.date.valueOf() < TIME) {
			showToast({
				type: 'error',
				message: 'Please select valid depature date '
			})
			return
		}

		let date = this.state.date;
		if (!date || date === "") {
			showToast({
				type: 'error',
				message: 'Please Select Valid Date'
			})
		}

		let data = {
			origin: origin.id,
			destination: destination.id,
		}

		let route = {
			route: data,
			date: Moment(date).format("DD-MM-YYYY"),
			counts: this.state.adult,
			type: this.state.type.value
		}





		this.props.search(route)

	}

	mapNotes = (item, index) => {
		return (
			<li style={{ fontSize: 12, padding: '8px 5px', color: '#7F7AA2' }} key={index}>{item}</li>
		)
	}

	render() {
		return (
			<div style={{
				width: 325,
				overflowX: 'hidden',
				background: "white",
				padding: "25px 18px",
				height: '100vh',
				overflowY: 'auto',
				borderRight: '1px solid #dedede'
			}}>
				<div>
					<h5 className="prime-color" style={{
						fontSize: 25,
						fontWeight: 800,
						textAlign: 'center',
						letterSpacing: 1,
						lineHeight: "35px",
						marginTop: 15
					}}>
						Book Flight Online
					</h5>
					<p style={{
						fontSize: 13,
						color: "#BABBCC",
						textAlign: 'center',
						lineHeight: "16px"
					}}>
						Please add below details and click on button below to search flights.
					</p>
					<div style={{
						width: 'calc(100% + 36px)',
						marginLeft: -18,
						marginRight: -18,
						marginTop: 30,
					}}>
						<img src={HotelSearch} alt="hotel search" style={{
							width: '100%'
						}} />

					</div>
					<p style={{
						fontSize: 11,
						color: "#555555",
						fontWeight: 600,
						marginBottom: 4,
						marginTop: 18
					}}>
						Origin City
					</p>
					<div style={{ position: 'relative' }}>
						<CitySearch disabled={this.state.requestId} airport={true} onCitySelected={originCity => {
							this.setState({
								...this.state,
								originCity
							})
						}}
							city={this.state.originCity}
						/>
					</div>
					<p style={{ marginTop: 16, fontSize: 11, marginBottom: 4, fontWeight: 600 }}>Destination City</p>
					<div style={{ position: 'relative' }}>
						<CitySearch disabled={this.state.requestId} airport={true} onCitySelected={destinationCity => {
							this.setState({
								...this.state,
								destinationCity
							})
						}}
							city={this.state.destinationCity}
						/>
					</div>
					<div style={{ position: 'relative', marginTop: 16 }}>
						<p style={{
							marginTop: 16,
							marginBottom: 4,
							fontWeight: 600,
							color: "#555555",
							fontSize: 11
						}}>
							Date
						</p>
						<DatesCompo disabled={this.state.requestId} selectDate={this.state.date} handleDate={date => {
							this.setState({
								date
							})
						}} />
					</div>
					<div style={{
						position: 'relative',
						marginTop: 10
					}}>

						<ShownSelect
							disabled={this.state.requestId}
							value={this.state.type}
							items={[
								{
									label: 'Economy',
									value: 'economy'
								},
								{
									label: 'Business',
									value: 'business'
								},
								{
									label: 'Premium',
									value: 'premium'
								},
								{
									label: 'First Class',
									value: 'first-class'
								}
							]}
							placeholder="Select flight type"
							select={(type) => {
								this.setState({
									...this.state,
									type
								})
							}}
						/>
					</div>
					<div style={{ position: 'relative', marginTop: 16 }}>
						<p style={{
							marginTop: 16,
							marginBottom: 12,
							fontWeight: 600,
							color: "#555555",
							fontSize: 11
						}}>
							Adults
						</p>
						<div style={{
							padding: "2px 10px",
							width: 80,
							flexDirection: 'row',
							display: 'flex',
							alignItems: 'center',
							border: '1px solid #6a48f2'
						}}>
							<span onClick={() => {
								if(this.state.requestId) return;
								let adult = this.state.adult;
								if (adult > 1) {
									adult--;
								}
								this.setState({
									...this.state,
									adult
								})
							}} style={{
								fontWeight: 600,
								color: "#6a48f2",
								fontSize: 17,
								lineHeight: "21px",
								cursor: 'pointer'
							}}>
								-
							</span>
							<p style={{
								flex: 1,
								textAlign: 'center',
								fontWeight: 600,
								color: "#6a48f2",
								fontSize: 13
							}}>
								{this.state.adult}
							</p>
							<span onClick={() => {
								if(this.state.requestId) return;
								let adult = this.state.adult;
								if (adult < 7) {
									adult++;
								}
								this.setState({
									...this.state,
									adult
								})
							}} style={{
								fontWeight: 600,
								color: "#6a48f2",
								fontSize: 17,
								lineHeight: "21px",
								cursor: 'pointer'
							}}>
								+
							</span>
						</div>
					</div>

					{
						this.props.result.polling ?
							<p className="prime-background" style={{
								textAlign: 'center',
								textTransform: 'uppercase',
								color: "white",
								fontWeight: 600,
								padding: '10px 20px',
								fontSize: 15,
								marginTop: 35,
								background: "#8558F6",
								cursor: 'pointer'
							}}>
								Searching...
							</p> :
							<p className="prime-background" style={{
								textAlign: 'center',
								textTransform: 'uppercase',
								color: "white",
								fontWeight: 600,
								padding: '10px 20px',
								fontSize: 15,
								marginTop: 35,
								background: "#7558F6",
								cursor: 'pointer'
							}} onClick={this.search}>
								Start New Booking
							</p>
					}
					{
						this.state.forms && this.state.forms.length>0?<div>
							<p style={{fontSize:13,color:"#565656",marginTop:30,marginBottom:15}}>
								Forms:-
							</p>
							{
								this.state.forms.map(item=>{
									return (<div key={item.key} style={{marginBottom:12}}>
										<p style={{fontSize:11,color:"#898989"}}>
											{item.key}
										</p>
										<p style={{fontSize:15,color:"#565656"}}>
											{item.value}
										</p>
									</div>)
								})
							}
						</div>:undefined
					}
				</div>
			</div>
		)
	}

}

export default SearchComponent
