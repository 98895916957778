import React from 'react'
import Props from 'prop-types'
import Close from "../../../libs/Modal/close.png";
import Guests from './Guests'
import Employee from './Employee'
import AddGuest from './AddGuest'

class ChooseTraveller extends React.Component {

	state = {
		type: 1
	}

	render() {
		if (!this.props.shown)
			return (
				<div>

				</div>
			)
		return (
			<div className="modal fade show"
				style={this.props.shown ? { display: "block", background: 'rgba(0,0,0,.4)' } : {}}>
				<div className="modal-dialog" role="document" style={{ marginTop: 80 }}>
					<div className="modal-content">
						<div>
							<h5 className="p-portlet-title" id="exampleModalLabel">
								Select Traveller
							</h5>
							<p className="p-portlet-des">
								Please select traveller from option below.
							</p>
							<img alt="close" style={{ width: 16, height: 16, marginTop: 10, cursor: 'pointer' }}
								src={Close} className="modal-close" onClick={this.props.close} />
						</div>
						<div className="modal-body">
							<div
								style={{ borderBottom: '2px solid rgb(241,241,241)', display: 'flex', marginBottom: 15 }}>
								<p onClick={() => {
									this.setState({
										type: 1
									})
								}} style={{
									padding: '0px 0 12px',
									marginRight: 32,
									fontSize: 15,
									color: 'inherit',
									textTransform: 'uppercase',
									cursor: 'pointer',
									position: 'relative',
									top: 3
								}} className={this.state.type === 1 ? "active-line" : null}>
									Search Employee
								</p>
								<p onClick={() => {
									this.setState({
										type: 2
									})
								}} style={{
									padding: '0px 0 12px',
									marginRight: 32,
									fontSize: 15,
									color: 'inherit',
									cursor: 'pointer',
									textTransform: 'uppercase',
									position: 'relative',
									top: 3
								}} className={this.state.type === 2 ? "active-line" : null}>
									Search Guest
								</p>
								<p onClick={() => {
									this.setState({
										type: 3
									})
								}} style={{
									padding: '0px 0 12px',
									cursor: 'pointer',
									marginRight: 32,
									fontSize: 15,
									color: 'inherit',
									textTransform: 'uppercase',
									position: 'relative',
									top: 3
								}} className={this.state.type === 3 ? "active-line" : null}>
									Add Guest
								</p>
							</div>
							{
								this.state.type === 1 ?
									<Employee panDetails={this.props.panDetails} passportDetails={this.props.passportDetails} country={this.props.country}  callback={data => {
										this.props.callback({
											type: 'EMPLOYEE',
											name: data.employee.label,
											reference: data.employee.value,
											email: data.employee.des,
											...data.passport?{passport: data.passport}:{},
											...data.pan?{panCardNo: data.pan}:{}
										})
									}} />
									: this.state.type === 2 ? <Guests panDetails={this.props.panDetails} passportDetails={this.props.passportDetails} country={this.props.country} callback={data => {
										this.props.callback({
											type: 'GUEST',
											name: data.guest.label,
											reference: data.guest.value,
											email: data.guest.des,
											...data.passport?{passport: data.passport}:{},
											...data.pan?{panCardNo: data.pan}:{}
										})
									}} /> : <AddGuest panDetails={this.props.panDetails} passportDetails={this.props.passportDetails} country={this.props.country} callback={data => {
										this.props.callback({
											type: 'GUEST',
											name: data.guest.label,
											reference: data.guest.value,
											email: data.guest.des,
											...data.passport?{passport: data.passport}:{},
											...data.pan?{panCardNo: data.pan}:{}
										})
									}} />
							}
						</div>
					</div>
				</div>
			</div>
		)
	}

}

ChooseTraveller.propTypes = {
	shown: Props.bool.isRequired
}

export {
	ChooseTraveller
}