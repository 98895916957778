import React from 'react'
import {TableActions} from "../../../libs/Table/TableAction";
import TripDetails from './TripDetails';
import ViewAdmin from './ViewAdmin';

class BusRequestItem extends React.Component {

	state = {
		showSidePane: false,
		viewAdmin:false
	}

	handleCloseSidePane = () => {
        this.setState({ showSidePane: false });
    }

	render() {
		let item = this.props.data;

		let toggleSidePane = () =>{
			this.setState({
				showSidePane: !this.state.showSidePane
			})
		}
		let onwards = () => {
			this.props.history.push(
				`/app/bus/search?requestId=${item.id}`
			)
		}
		let round = () => {
			this.props.history.push(
				`/app/bus/search?requestId=${item.id}&type=return`
			)
		}
		let onwardBooked = () => {
			this.props.history.push(`/app/bus/tickets/${item.onwardsTransactionId}`)
		}

		let returnBooked = () => {
			this.props.history.push(`/app/bus/tickets/${item.returnTransactionId}`)
		}

		let actions = [];

		if(item.onwardJourney){
			actions.push({
				name : "Book Onwards",
				click : onwards
			})
		}

		if(!item.onwardJourney) {
			actions.push({
				name:'Onwards Booked',
				click: onwardBooked
			})
		}

		if(item.returnJourney){
			actions.push({
				name : "Book Return",
				click : round
			})
		}

		if(!item.returnJourney) {
			actions.push({
				name:'Return Booked',
				click: returnBooked
			})
		}

		actions.push({
			name: "Close Booking",
			click:()=>{
				this.props.onClose({
					id:item.id,
					service:'bus'
				})
			}
		})
		
		actions.push({
			name: "Decline",
			click:()=>{
				this.props.onDecline({
					id:item.id,
					service:"bus"
				})
			}
		})

		actions.push({
			name: "View Admin",
			click:()=>{
				this.setState((prev)=>{
					return {...prev,viewAdmin:true}
				})
			}
		})

		if(this.props.closed){
			return (
				<div className="fintrip-department-item table-item" style={{
					borderBottom: "1px solid #dedede",
					display: 'flex',
					cursor: 'pointer',
					flexDirection: 'row',
					padding: '12px 0'
				}}>
					<div style={{flex: 1, alignItems: 'center'}} className="linear-layout horizontal">
						<div>
							<img src={item.employee.image} alt="employee" style={{
								width: 30, height: 30, borderRadius: 15,
								marginRight: 8
							}}/>
						</div>
						<div>
					     	<p className="top">{item.guestName || item.employee.name}</p>
							 {item.guestName && <p className="bottom">{"(Guest)"}</p>}
							 <p className="bottom">{item.guestEmail || item.employee.email}</p>
							<p className="bottom">{item.employee.code}</p>
						</div>
					</div>
					<div style={{
						flex: 1
					}}>
						<p style={{
							fontWeight: 700,
							fontSize: 14,
							textAlign: 'center'
						}} className="top">Bus</p>
						<p  style={{
							textAlign: 'center'
						}} className="bottom">
							{ item.originName } - {item.destinationName}
						</p>
						<p  style={{
							textAlign: 'center'
						}} className="bottom">
							{ item.returnDate?`${item.departureDate} - ${item.returnDate}` :`${item.departureDate}` }
						</p>
					</div>
					<div style={{flex: 1, alignItems: 'center'}} className="linear-layout horizontal">
						<p style={{
							fontWeight: 700,
							fontSize: 14
						}} className="top">{item.tripId}</p>
					</div>
					<div style={{flex:1}}>
						<p style={{
							fontWeight: 700,
							fontSize: 14,
							textAlign: 'center'
						}} className="top">{item.status}</p>
					</div>
				</div>
			)
		}
		return (
			<>
			{
				this.state.showSidePane ?
					<TripDetails onClose={(this.handleCloseSidePane)} tripId={item.tripId}/>
				:null
			}
			{
				this.state.viewAdmin?<ViewAdmin init={()=>{this.props.init()}} onClose={()=>{this.setState((prev)=>({...prev,viewAdmin:false}))}} item={item}/>:null
			}
			<div className="fintrip-department-item table-item" 
			style={{
				borderBottom: "1px solid #dedede",
				display: 'flex',
				cursor: 'pointer',
				flexDirection: 'row',
				padding: '12px 0'
			}}>
				<div style={{flex: 1, alignItems: 'center'}} className="linear-layout horizontal">
					<div>
						<img src={item.employee.image} alt="employee" style={{
							width: 30, height: 30, borderRadius: 15,
							marginRight: 8
						}}/>
					</div>
					<div>
					    <p className="top">{item.guestName || item.employee.name}</p>
						{item.guestName && <p className="bottom">{"(Guest)"}</p>}
						<p className="bottom">{item.guestEmail || item.employee.email}</p>
						<p className="bottom">{item.employee.code}</p>
					</div>
				</div>
				<div style={{
					flex: 1
				}}>
					<p style={{
						fontWeight: 700,
						fontSize: 14
					}} className="top">Bus</p>
					<p className="bottom">
						{ item.originName } - {item.destinationName}
					</p>
					<p className="bottom">
						{ item.returnDate?`${item.departureDate} - ${item.returnDate}` :`${item.departureDate}` }
					</p>
				</div>
				<div style={{flex: 1, alignItems: 'center'}} className="linear-layout horizontal">
					<p style={{
						fontWeight: 700,
						fontSize: 14,
					}} className="top">{item.tripId}</p>
				</div>
				<div style={{flex:1.4}} className="right-actions">
					<TableActions items={actions}/>
					{item.tripId ? <p onClick={toggleSidePane} className="hoverable" >View Details</p> : null}
					{item.returnJourney?<p onClick={round}  className="hoverable" style = {{fontSize:10}}>Return</p>:<p onClick = {returnBooked} className = "hoverable" style = {{fontSize:10}}>Return Booked</p>}
					{item.onwardJourney?<p onClick={onwards}  className="hoverable" style = {{fontSize:10}}>Onwards</p>:<p onClick = {onwardBooked} className = "hoverable" style = {{fontSize:10}}>Onwards Booked</p>}
				</div>
			</div>
		</>
		)
	}

}

export default BusRequestItem
