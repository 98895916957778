import HotelIcon from './hotel.png'
import BusIcon from './bus.png'
import GuestIcon from './guest.png'
import TaxiIcon from './taxi.png';
import train from './train.png';
import VisaIcon from './visa.svg';
import TripIcon from './trip.svg';
//import ChatIcon from './icon_chat.png'

const Bookings = [
	{
		value: 'Home',
		path: '/app/home',
		link: "/app/home",
		icon: require('./home.png'),
		active: 'linear-gradient( 70.7deg,  rgba(241,113,40,1) 1.1%, rgba(245,165,130,1) 86.6% )',
		inactive: 'linear-gradient( 107deg,  rgba(72,81,86,.3) 11.2%, rgba(187,187,187,.3) 90.7% )'
	},
	{
		value: 'Flights',
		path: '/app/flights',
		link: "/app/flights/search",
		icon: require('./plne.png'),
		active: 'linear-gradient( 109.6deg,  rgba(211,22,22,1) 11.2%, rgba(244,17,195,1) 91.1% )',
		inactive: 'linear-gradient( 107deg,  rgba(72,81,86,.3) 11.2%, rgba(187,187,187,.3) 90.7% )'
	},
	{
		value: 'Hotels',
		path: '/app/hotel',
		link: "/app/hotel/search",
		icon: HotelIcon,
		active: 'radial-gradient( circle 293px at 8.3% 20.7%,  rgba(255,128,8,1) 0%, rgba(255,200,55,1) 90% )',
		inactive: 'linear-gradient( 107deg,  rgba(72,81,86,.3) 11.2%, rgba(187,187,187,.3) 90.7% )'
	},
	{
		value: 'Bus',
		path: '/app/bus',
		link: "/app/bus/search",
		icon: BusIcon,
		active: 'linear-gradient( 103deg,  rgba(54,209,220,1) 15.7%, rgba(91,134,229,1) 88.8% )',
		inactive: 'linear-gradient( 107deg,  rgba(72,81,86,.3) 11.2%, rgba(187,187,187,.3) 90.7% )'
	},
	{
		value: 'cabs',
		path: '/app/cabs',
		link: "/app/cabs/request",
		icon: TaxiIcon,
		active: 'linear-gradient( 179.3deg,  rgba(80,161,252,1) 0.3%, rgba(1,98,203,1) 96.9% )',
		inactive: 'linear-gradient( 107deg,  rgba(72,81,86,.3) 11.2%, rgba(187,187,187,.3) 90.7% )'
	},
	{
		value: 'Train',
		path: '/app/train',
		link: '/app/train/request',
		icon: train,
		active: 'linear-gradient( 70.7deg,  rgba(241,113,40,1) 1.1%, rgba(245,165,130,1) 86.6% )',
		inactive: 'linear-gradient( 107deg,  rgba(72,81,86,.3) 11.2%, rgba(187,187,187,.3) 90.7% )'

	},
	{
		value: 'Travellers',
		path: '/app/travellers',
		link: "/app/travellers",
		icon: GuestIcon,
		active: 'radial-gradient( circle farthest-corner at 10% 20%, rgba(58,67,190,1) 0%, rgba(109,115,236,1) 90.1% )',
		inactive: 'linear-gradient( 107deg,  rgba(72,81,86,.3) 11.2%, rgba(187,187,187,.3) 90.7% )'
	},
	{
		value: 'Visa',
		path: '/app/visa',
		link: "/app/visa",
		icon: VisaIcon,
		active: 'radial-gradient( circle farthest-corner at 10% 20%, rgba(58,67,190,1) 0%, rgba(109,115,236,1) 90.1% )',
		inactive: 'linear-gradient( 107deg,  rgba(72,81,86,.3) 11.2%, rgba(187,187,187,.3) 90.7% )'
	},
	{
		value: 'Trip',
		path: '/app/trip',
		link: "/app/trip",
		icon: TripIcon,
		active: 'radial-gradient( circle farthest-corner at 10% 20%, rgba(58,67,190,1) 0%, rgba(109,115,236,1) 90.1% )',
		inactive: 'linear-gradient( 107deg,  rgba(72,81,86,.3) 11.2%, rgba(187,187,187,.3) 90.7% )'
	}
];

const setting = {
	active: 'radial-gradient( circle farthest-corner at 10% 20%, rgba(58,67,190,1) 0%, rgba(109,115,236,1) 90.1% )',
	inactive: 'linear-gradient( 107deg,  rgba(72,81,86,.3) 11.2%, rgba(187,187,187,.3) 90.7% )'
}

export { Bookings, setting };